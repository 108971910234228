import { useIntervalFn } from '@vueuse/core'
import { computed, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NotFoundException, useApi } from '@/functions/api'
import { generateLinkWhatsapp } from '@/functions/whatsapp'
import { usePageStore } from '@/stores/page'
import { useToastStore } from '@/stores/toast'
import { useUserStore } from '@/stores/user'
import { useNotificationList } from './notification'
import { removeSellRentVariantsFromProducts } from './product'

export const authPages = [
  'login',
  'login with email',
  'forget password',
  'register',
  'welcome',
  'callback provider',
]
export const guestPages = [
  'terms condition',
  'privacy policy',
  'shopping list detail',
  'project detail',
  'home',
  'service',
  'service detail',
  'product list',
  'product detail',
]

export const helpCenterUrl = generateLinkWhatsapp('Hallo Masa, saya ingin bertanya...')
export function useInit() {
  const page = usePageStore()
  const user = useUserStore()

  const api = useApi()

  const initApp = async () => {
    const response = await api.GET<{ user: AuthenticatedUser }>('customer/app/init')
    user.init(response.user)
    page.setAuthenticated()
    getNotificationCount()
  }

  const { pagination, getData: getNotifications } = useNotificationList()
  const getNotificationCount = async () => {
    await getNotifications({ per_page: 1, unread: 1 })
    page.setUnreadNotificationsCount(pagination.value?.total ?? 0)
  }

  return {
    initApp,
  }
}

export function useRedirectByAuthStatus() {
  const route = useRoute()
  const router = useRouter()
  const page = usePageStore()
  const user = useUserStore()
  const { getRedirectFrom, clearRedirectFrom, setRedirectFrom } = useAppRedirectOnLogin()
  const routeName = computed(() => (route.name as string | undefined) ?? '')
  const isInAuthPages = computed(() => authPages.includes(routeName.value))
  const isInGuestPages = computed(() => guestPages.includes(routeName.value))

  watch(
    () => [page.authenticated, routeName],
    () => {
      if (!routeName.value) return

      // not authenticated, but in auth/guest pages, no redirect
      if (page.isNotAuthenticated && (isInAuthPages.value || isInGuestPages.value)) {
        return
      }

      // not authenticated, not in auth/guest pages
      // save current path, redirect to login page
      // then after authenticated, restore current page
      if (page.isNotAuthenticated) {
        setRedirectFrom(route.fullPath)
        router.replace({ name: 'login' })
        return
      }

      // after registration with email, user will need to set name
      if (page.isAuthenticated && user.profileIncomplete) {
        router.replace({ name: 'register set missing data' })
        return
      }

      // after authenticated, redirect to home page or previous
      // page opened before login
      if (page.isAuthenticated && isInAuthPages.value) {
        const destination = getRedirectFrom()
        router.replace(destination || { name: 'home' })
        clearRedirectFrom()
        return
      }
    },
  )
}

export function useLogout() {
  const api = useApi()
  const page = usePageStore()
  const user = useUserStore()

  return () => {
    api.POST('logout', { device_key: user.device_key })
    page.setNotAuthenticated()
    user.$reset()
  }
}

export function useAppRedirectOnLogin() {
  const key = 'unauthenticated_redirected_from'
  const setRedirectFrom = (path: string) => {
    localStorage.setItem(key, path)
  }
  const getRedirectFrom = () => localStorage.getItem(key)
  const clearRedirectFrom = () => localStorage.removeItem(key)

  return {
    setRedirectFrom,
    getRedirectFrom,
    clearRedirectFrom,
  }
}

export function usePageDetail() {
  const loading = ref(false)
  const showNotFoundError = ref(false)
  const tryCatchNotFound = async (callback: () => Promise<void>) => {
    try {
      loading.value = true
      return await callback()
    } catch (error) {
      if (error instanceof NotFoundException) {
        showNotFoundError.value = true
      }
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    showNotFoundError,
    tryCatchNotFound,
  }
}

export function useDeleteAccount() {
  const router = useRouter()
  const page = usePageStore()
  const user = useUserStore()
  const toast = useToastStore()
  const api = useApi()

  const errors = ref<FormError>({})
  const submitting = ref(false)
  const form = reactive({
    confirmation_code: '',
  })
  const submitForm = async () => {
    try {
      submitting.value = true
      await api.DELETE('customer/user/delete-account', form)
      page.setNotAuthenticated()
      user.$reset()
      toast.add('Akun berhasil dihapus')
      await router.replace({ name: 'login' })
    } catch (error) {
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  const ONE_MINUTE = 60 // seconds
  const ONE_SECOND = 1000 // milliseconds
  const resendCountdown = ref(0)
  const resendingOtp = ref(false)
  const firstOtpIsSent = ref(false)
  useIntervalFn(() => {
    return resendCountdown.value > 0 ? resendCountdown.value-- : 0
  }, ONE_SECOND)
  const resetCountdown = () => {
    resendCountdown.value = ONE_MINUTE
  }
  const requestOtp = async () => {
    if (resendCountdown.value > 0) {
      return
    }
    resendingOtp.value = true
    await api.POST('customer/user/request-delete-account')
    resendingOtp.value = false
    firstOtpIsSent.value = true
    resetCountdown()
  }

  return {
    submitForm,
    requestOtp,
    firstOtpIsSent,
    resendCountdown,
    resendingOtp,
    submitting,
    form,
    errors,
  }
}

export function useAppSearchGlobal() {
  const api = useApi()
  const loading = ref(false)
  const sells = ref<Product[]>([])
  const rents = ref<Product[]>([])
  const services = ref<ServiceItem[]>([])

  const resetData = () => {
    sells.value = []
    rents.value = []
    services.value = []
  }

  const getData = async (search: string) => {
    loading.value = true
    try {
      const response = await api.GET<{
        sells: Product[]
        rents: Product[]
        services: ServiceItem[]
      }>('guest/app/search', { search })
      sells.value = removeSellRentVariantsFromProducts(response.sells, 'jual')
      rents.value = removeSellRentVariantsFromProducts(response.rents, 'sewa')
      services.value = response.services
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    sells,
    rents,
    services,
    getData,
    resetData,
  }
}
