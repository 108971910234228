import { capitalCase, kebabCase as kebab } from 'change-case'
import Copy from 'clipboard-copy'
import fnDebounce from 'debounce'
import { nextTick, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useToastStore } from '@/stores/toast'
import byte from './fmt/byte'
import DateFormatter from './fmt/date'
import duration from './fmt/duration'
import NumberFormatter from './fmt/number'

export { nanoid as randomId } from 'nanoid'

export const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}
export const kebabCase = (str: string) => kebab(decodeHtml(str))
export const titleCase = (str: string) => capitalCase(decodeHtml(str))

export const debounce = fnDebounce

export const fmt = {
  ...DateFormatter,
  ...NumberFormatter,
  byte,
  duration,
}

export const base64ToBlob = async (base64Image: string) => {
  const res = await fetch(base64Image)
  return await res.blob()
}

/**
 * Watch the route full path, and trigger callback when query / params change
 * but don't trigger when route name change (move away to other page).
 * Optional watchSource to watch just specific part of the route (not fullPath).
 */
export const whenRouteChange = (callback: () => void, watchSource?: () => unknown) => {
  const route = useRoute()
  const routeName = route.name // cache route name
  watch(
    watchSource ?? (() => route.fullPath),
    () => {
      if (route.name === routeName) {
        nextTick(callback)
      }
    },
    { immediate: true },
  )
}

export const copy = async (text: string) => {
  const toast = useToastStore()
  try {
    await Copy(text)
    toast.add('Teks telah disalin')
  } catch (error) {
    toast.add('Gagal menyalin teks')
    console.log(error)
  }
}

export const useDocumentOverflow = () => {
  const disableOverflow = () => {
    document.body.style.overflowY = 'hidden'
  }
  const reenableOverflow = () => {
    // delay because of vue lightbox
    setTimeout(() => {
      document.body.style.overflowY = ''
    }, 200)
  }
  return {
    disableOverflow,
    reenableOverflow,
  }
}

export const useEscListener = (callback: () => void) => {
  return (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      callback()
    }
  }
}

export const useStorageUrl = (path: string, css: boolean = false) => {
  // rtrim trailing slash from env var
  const storageUrl = 'https://d1g3l6rc2bqzsq.cloudfront.net'

  const url = `${storageUrl}/${path}`

  return css ? `url('${url}')` : url
}
