<script setup lang="ts">
  import { useWindowScroll } from '@vueuse/core'
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { usePageStore } from '@/stores/page'
  import TheNavbarSearch from './the-navbar-search.vue'

  const route = useRoute()
  const { y } = useWindowScroll()
  const navbarHasBackgroundWhenScrolled = 120

  const routeNameWithTransparentBg = ['home', 'service']
  const isTransparentBg = computed(() => {
    return routeNameWithTransparentBg.includes(route.name as string)
  })

  const page = usePageStore()
</script>

<template>
  <nav
    class="navbar pb-4 transition-all duration-500 max-lg:rounded-b-5 lg:bg-white"
    :class="{ '!max-lg:top-0 max-lg:fixed max-lg:inset-x-0': y > 100 }"
  >
    <div class="container">
      <div
        class="relative z-10 pb-3 pt-2.5 transition-colors duration-500 max-lg:rounded-b-5 max-lg:bg-primary max-lg:bg-opacity-80 lg:pt-0"
        :class="{
          '!bg-opacity-0': isTransparentBg && y < navbarHasBackgroundWhenScrolled,
        }"
      >
        <div class="flex gap-x-8 px-4 lg:items-start lg:px-0">
          <app-button
            class="w-60 max-lg:hidden"
            :to="{ name: 'home' }"
          >
            <app-img
              class="w-28"
              src="/images/masa-logo.webp"
            />
          </app-button>

          <div class="flex-1 lg:pt-5.5">
            <the-navbar-search />
          </div>

          <div
            v-if="page.isAuthenticated"
            class="flex lg:pt-7.5"
          >
            <app-button
              :to="{ name: 'notification' }"
              class="flex-center text-lg text-white lg:text-primary-400"
            >
              <fa-icon icon="i-fas-bell" />
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="postcss" scoped>
  .navbar {
    @apply -top-full z-20 lg:top-0 lg:fixed lg:inset-x-0 lg:pt-10;
  }
</style>
