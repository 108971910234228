<script lang="ts">
  import { useWindowSize } from '@vueuse/core'
  import { h, ref, watch } from 'vue'
  import { debounce, randomId } from '@/functions'

  export default {
    props: {
      lineClamp: {
        type: Number,
        default: 3,
      },
    },
    setup(props, { slots }) {
      if (!slots.default || slots.default().length !== 1) {
        throw new Error('app-text-show-more must have exactly one child')
      }

      const id = randomId()
      const expanded = ref(false)

      const clampable = ref(false)
      const setClampable = debounce(() => {
        const el = document.getElementById(id)
        clampable.value = Math.floor((el?.scrollHeight ?? 0) / 20) > props.lineClamp
      })

      const { width } = useWindowSize()
      watch(width, setClampable)

      const textEl = slots.default()[0]

      return () =>
        h('div', [
          h(textEl, {
            id,
            style: !expanded.value
              ? {
                  overflow: 'hidden',
                  display: '-webkit-box',
                  '-webkit-box-orient': 'vertical',
                  'line-clamp': props.lineClamp,
                  '-webkit-line-clamp': props.lineClamp,
                }
              : {},
            onVnodeUpdated: setClampable,
          }),

          clampable.value
            ? h(
                'button',
                {
                  type: 'button',
                  class: 'py-1 text-xs text-blue font-poppins',
                  onClick: () => (expanded.value = !expanded.value),
                },
                expanded.value ? 'Tampilkan sedikit' : 'Tampilkan lebih',
              )
            : null,
        ])
    },
  }
</script>
