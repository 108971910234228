<script setup lang="ts">
  import { useDropZone } from '@vueuse/core'
  import { computed, ref, watchEffect } from 'vue'
  import { useRoute } from 'vue-router'
  import TheMenuBar from '@/components/menu-bar/the-menu-bar.vue'
  import TheNavbar from '@/components/navbar/the-navbar.vue'
  import { authPages, guestPages } from '@/models/app'
  import { useProductCategoryList } from '@/models/product-category'
  import { useServiceCategoryList } from '@/models/service'
  import { usePageStore } from '@/stores/page'
  import { useShoppingListStore } from '@/stores/shopping-list'

  const route = useRoute()

  const page = usePageStore()

  const refDropZone = ref<HTMLElement>()
  const { isOverDropZone } = useDropZone(refDropZone)
  watchEffect(() => {
    page.setUserDraggingFile(isOverDropZone.value)

    // if (page.isAuthenticated) {
    // TODO move to page /notifikasi and run when user click button to enable notification
    // startFCM()
    // }
  })

  const shoppingList = useShoppingListStore()
  shoppingList.load()
  shoppingList.$subscribe((mutation, state) => {
    // when shopping list updated (qty, discount, notes, etc),
    // 1. if url is generated, clear the url to unhide the save button
    if (mutation.type === 'direct') {
      shoppingList.setLink('')
    }
    // 2. save to localstorage
    shoppingList.saveToLocalStorage(state)
  })

  const { getDataCategories } = useServiceCategoryList()
  const { getCategories } = useProductCategoryList()
  getDataCategories()
  getCategories()

  const showMenuBar = computed(() => !authPages.includes(route.name as string))

  // prevent loading user data when user is not authenticated and will
  // be redirected to login page (e.g notification / transaction pages)
  const showContent = computed(
    () => page.isAuthenticated || guestPages.includes(route.name as string),
  )
</script>

<template>
  <div>
    <div
      v-if="showContent"
      ref="refDropZone"
      class="relative"
    >
      <the-navbar />

      <div
        class="pb-8 container"
        :class="{
          'max-lg:pb-28': route.name !== 'home',
        }"
      >
        <app-router-view />
      </div>
    </div>

    <the-menu-bar v-if="showMenuBar" />
  </div>
</template>
