<script setup lang="ts">
  import { useDropZone } from '@vueuse/core'
  import { ref } from 'vue'
  import { usePageStore } from '@/stores/page'

  const emit = defineEmits<{
    (e: 'fileDropped', files: File[]): void
  }>()

  const page = usePageStore()

  const dropZoneRef = ref<HTMLElement>()

  function onDrop(files: File[] | null) {
    if (!files) return
    emit('fileDropped', files)
  }

  useDropZone(dropZoneRef, onDrop)
</script>

<template>
  <div
    v-if="page.userDraggingFile"
    ref="dropZoneRef"
    class="flex-center bg-blue bg-opacity-40 text-4xl text-white backdrop-blur-1"
  >
    Seret file ke sini
  </div>
</template>
