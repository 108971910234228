import { ref } from 'vue'
import { useApi } from '@/functions/api'
import { usePageStore } from '@/stores/page'

interface AppNotification {
  id: string
  type: string
  title: string
  body: string
  url: string
  read_at: string | null
  created_at: string
}

export function useNotificationList() {
  const page = usePageStore()
  const api = useApi()

  const loading = ref(false)
  const notifications = ref<AppNotification[]>([])
  const pagination = ref<Pagination>()

  const getData = async (apiParams: object) => {
    loading.value = true
    const response = await api.GET<ApiResponse<AppNotification>>(
      '/notification/customer',
      apiParams,
    )
    notifications.value = response.data
    pagination.value = response.meta
    loading.value = false
  }

  const markReadOne = async (id: string) => {
    // update notification read_at
    const index = notifications.value.findIndex((n) => n.id === id)

    if (index === -1 || notifications.value[index].read_at !== null) return

    api.POST(`notification/read/${id}`)
    notifications.value[index].read_at = new Date().toISOString()

    // update sidebar unread count
    page.setUnreadNotificationsCount(Math.max(page.unreadNotificationsCount - 1, 0))
  }

  const markReadAll = () => {
    api.POST('notification/read-all/customer')
    notifications.value = notifications.value.map((n) => ({
      ...n,
      read_at: new Date().toISOString(),
    }))

    // update sidebar unread count
    page.setUnreadNotificationsCount(0)
  }

  return {
    loading,
    notifications,
    pagination,
    getData,
    markReadOne,
    markReadAll,
  }
}
