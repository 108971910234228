import type { App } from 'vue'
import AppBackLink from './app-back-link.vue'
import AppBadge from './app-badge.vue'
import AppButtonBack from './app-button-back.vue'
import AppButton from './app-button.vue'
import AppCard from './app-card.vue'
import AppCheckbox from './app-checkbox.vue'
import AppDiscount from './app-discount.vue'
import AppDropZone from './app-drop-zone.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppFilterStatusItem from './app-filter-status-item.vue'
import AppFilterStatus from './app-filter-status.vue'
import AppFormSection from './app-form-section.vue'
import AppForm from './app-form.vue'
import AppHtml from './app-html.vue'
import AppIcon from './app-icon.vue'
import AppImgEmpty from './app-img-empty.vue'
import AppImgWp from './app-img-wp.vue'
import AppImg from './app-img.vue'
import AppInfiniteScroll from './app-infinite-scroll.vue'
import AppInputDate from './app-input-date.vue'
import AppInputFile from './app-input-file.vue'
import AppInputPassword from './app-input-password.vue'
import AppInput from './app-input.vue'
import AppLoadingDot from './app-loading-dot.vue'
import AppNotice from './app-notice.vue'
import AppNumeric from './app-numeric.vue'
import AppPagination from './app-pagination.vue'
import AppRouterView from './app-router-view.vue'
import AppSearchInput from './app-search-input.vue'
import AppSearchPage from './app-search-page.vue'
import AppTextShowMore from './app-text-show-more.vue'
import AppUserPhoto from './app-user-photo.vue'
import UseDropdown from './dropdown'
import FaIcon from './fa-icon.vue'
import UseMarkdown from './markdown'
import UseModal from './modal'

export default {
  install(app: App) {
    app.component('AppBackLink', AppBackLink)
    app.component('AppBadge', AppBadge)
    app.component('AppButton', AppButton)
    app.component('AppButtonBack', AppButtonBack)
    app.component('AppCheckbox', AppCheckbox)
    app.component('AppCard', AppCard)
    app.component('AppDiscount', AppDiscount)
    app.component('AppDropZone', AppDropZone)
    app.component('AppFilterStatus', AppFilterStatus)
    app.component('AppFilterStatusItem', AppFilterStatusItem)
    app.component('AppFormSection', AppFormSection)
    app.component('AppForm', AppForm)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppHtml', AppHtml)
    app.component('AppIcon', AppIcon)
    app.component('AppImg', AppImg)
    app.component('AppImgWp', AppImgWp)
    app.component('AppImgEmpty', AppImgEmpty)
    app.component('AppInfiniteScroll', AppInfiniteScroll)
    app.component('AppInput', AppInput)
    app.component('AppInputDate', AppInputDate)
    app.component('AppInputFile', AppInputFile)
    app.component('AppInputPassword', AppInputPassword)
    app.component('AppLoadingDot', AppLoadingDot)
    app.component('AppNotice', AppNotice)
    app.component('AppNumeric', AppNumeric)
    app.component('AppPagination', AppPagination)
    app.component('AppRouterView', AppRouterView)
    app.component('AppSearchInput', AppSearchInput)
    app.component('AppSearchPage', AppSearchPage)
    app.component('AppTextShowMore', AppTextShowMore)
    app.component('AppUserPhoto', AppUserPhoto)
    app.component('FaIcon', FaIcon)
    app.use(UseMarkdown)
    app.use(UseModal)
    app.use(UseDropdown)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppBackLink: typeof AppBackLink
    AppBadge: typeof AppBadge
    AppButton: typeof AppButton
    AppButtonBack: typeof AppButtonBack
    AppCard: typeof AppCard
    AppCheckbox: typeof AppCheckbox
    AppDiscount: typeof AppDiscount
    AppDropZone: typeof AppDropZone
    AppFilterStatus: typeof AppFilterStatus
    AppFilterStatusItem: typeof AppFilterStatusItem
    AppFormSection: typeof AppFormSection
    AppForm: typeof AppForm
    AppErrorMsg: typeof AppErrorMsg
    AppHtml: typeof AppHtml
    AppIcon: typeof AppIcon
    AppImg: typeof AppImg
    AppImgWp: typeof AppImgWp
    AppImgEmpty: typeof AppImgEmpty
    AppInfiniteScroll: typeof AppInfiniteScroll
    AppInput: typeof AppInput
    AppInputDate: typeof AppInputDate
    AppInputFile: typeof AppInputFile
    AppInputPassword: typeof AppInputPassword
    AppLoadingDot: typeof AppLoadingDot
    AppNotice: typeof AppNotice
    AppNumeric: typeof AppNumeric
    AppPagination: typeof AppPagination
    AppRouterView: typeof AppRouterView
    AppSearchInput: typeof AppSearchInput
    AppSearchPage: typeof AppSearchPage
    AppTextShowMore: typeof AppTextShowMore
    AppUserPhoto: typeof AppUserPhoto
    FaIcon: typeof FaIcon
  }
}
