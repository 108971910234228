import { defineStore } from 'pinia'
import { decodeHtml } from '@/functions'

const APP_TITLE = import.meta.env.VITE_APP_TITLE ?? 'Masa Building Solution'

const AUTH_TRUE = 1
const AUTH_FALSE = 0
const AUTH_UNKNOWN = -1

export const usePageStore = defineStore('Page', {
  state: () => ({
    title: '',
    subtitle: '',
    authenticated: AUTH_UNKNOWN as -1 | 0 | 1,
    userDraggingFile: false,
    maintenance: false,
    unreadNotificationsCount: 0,
  }),
  getters: {
    isAuthenticated: (state) => state.authenticated === AUTH_TRUE,
    isNotAuthenticated: (state) => state.authenticated === AUTH_FALSE,
    isAuthenticating: (state) => state.authenticated === AUTH_UNKNOWN,
  },
  actions: {
    setTitle(title: string) {
      setTimeout(() => {
        this.title = decodeHtml(title)
        document.title = `${this.title} | ${APP_TITLE}`
      }, 250)
    },
    setSubtitle(subtitle: string) {
      setTimeout(() => {
        // wait until page transition is done
        this.subtitle = decodeHtml(subtitle)
      }, 250)
    },
    setAuthenticated() {
      this.authenticated = AUTH_TRUE
    },
    setNotAuthenticated() {
      this.authenticated = AUTH_FALSE
    },
    setUserDraggingFile(userDraggingFile: boolean) {
      this.userDraggingFile = userDraggingFile
    },
    setMaintenance(maintenance: boolean) {
      this.maintenance = maintenance
    },
    setUnreadNotificationsCount(count: number) {
      this.unreadNotificationsCount = count
    },
  },
})
