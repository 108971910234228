<script setup lang="ts">
  import { computed } from 'vue'

  type ImageSize = number | [number, number] // number = square, [width, height] = rectangle
  type ImageSizes =
    | ImageSize
    | {
        xs?: ImageSize
        sm?: ImageSize
        md?: ImageSize
        lg?: ImageSize
        xl?: ImageSize
        '2xl'?: ImageSize
      }

  // img-wp will only have 1 src, append ?resize=width,height or ?fit=width,height to the src
  const props = defineProps<{
    src: string
    resize?: ImageSizes // put width,height here
    fit?: ImageSizes // put width,height here
    alt?: string
  }>()

  const convertImageSize = (size: ImageSize): string => {
    if (Array.isArray(size)) return size.join(',')
    return size.toString() + ',' + size.toString()
  }

  const setUrlResize = (resize: ImageSize) => {
    const url = props.src.split('?')[0]
    const queryParams = props.src.split('?')[1] ?? ''
    const searchParams = new URLSearchParams(queryParams)
    searchParams.set('resize', convertImageSize(resize))
    searchParams.delete('fit')
    return url + '?' + searchParams.toString()
  }
  const setUrlFit = (fit: ImageSize) => {
    const url = props.src.split('?')[0]
    const queryParams = props.src.split('?')[1] ?? ''
    const searchParams = new URLSearchParams(queryParams)
    searchParams.set('fit', convertImageSize(fit))
    searchParams.delete('resize')
    return url + '?' + searchParams.toString()
  }

  const url = computed(() => {
    if (props.fit && typeof props.fit !== 'object') {
      return setUrlFit(props.fit)
    }
    if (props.resize && typeof props.resize !== 'object') {
      return setUrlResize(props.resize)
    }
    return setUrlResize(420)
  })
  const urlSrcset = computed(() => {
    // resize and fit is not defined, srcset should be undefined
    if (!props.resize && !props.fit) return undefined
    // resize or fit is a number, srcset should be undefined
    if (typeof props.resize === 'number' || typeof props.fit === 'number')
      return undefined
    // resize or fit is a [width,height], srcset should be undefined
    if (Array.isArray(props.resize) || Array.isArray(props.fit)) return undefined

    const fnMode = (value?: ImageSize) => {
      if (!value) return undefined
      return props.fit ? setUrlFit(value) : setUrlResize(value)
    }

    return {
      xs: fnMode(props.resize?.xs ?? props.fit?.xs),
      sm: fnMode(props.resize?.sm ?? props.fit?.sm),
      md: fnMode(props.resize?.md ?? props.fit?.md),
      lg: fnMode(props.resize?.lg ?? props.fit?.lg),
      xl: fnMode(props.resize?.xl ?? props.fit?.xl),
      '2xl': fnMode(props.resize?.['2xl'] ?? props.fit?.['2xl']),
    }
  })
</script>

<template>
  <app-img
    :src="url"
    :srcset="urlSrcset"
    type="image"
  />
</template>
