<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useAndroidSwipeRefreshLayoutHandler } from '@/functions/android-swipe-handler'
  import SearchResult from '../search-global/search-result.vue'

  const route = useRoute()
  const search = ref('')
  const isOpenSearch = ref(false)
  const { setWatcher } = useAndroidSwipeRefreshLayoutHandler()
  setWatcher(isOpenSearch)

  const inputElement = () => {
    return document.querySelector<HTMLInputElement>('#globalSearch')
  }

  const openSearch = () => {
    isOpenSearch.value = true
  }

  const closeSearch = () => {
    inputElement()?.blur()
    isOpenSearch.value = false
  }

  onMounted(() => {
    inputElement()?.addEventListener('focus', openSearch)
  })
  onBeforeUnmount(() => {
    inputElement()?.removeEventListener('focus', openSearch)
  })

  const handleKeydown = (key: KeyboardEvent) => {
    if (key.key === 'Escape') {
      closeSearch()
    }
  }

  watch(() => route.fullPath, closeSearch)
</script>

<template>
  <div
    v-on-click-outside="closeSearch"
    class="flex-1 lg:relative"
  >
    <div class="relative flex items-center">
      <input
        id="globalSearch"
        v-model="search"
        autocomplete="off"
        placeholder="Cari produk"
        class="h-9 w-full border rounded-lg pl-10 font-roboto"
        @keydown="handleKeydown"
      />

      <fa-icon
        icon="i-far-magnifying-glass"
        class="absolute left-3 text-gray-400"
      />

      <img
        v-if="search.length === 0"
        src="/images/masa-logo-small.png"
        class="pointer-events-none absolute left-35 top-1.25 h-5.5"
        alt="logo"
      />

      <app-button
        v-if="search"
        class="absolute inset-y-0 right-1 z-20 my-auto size-6 flex-center rounded-full text-xs text-gray hover:bg-gray-100"
        @click="search = ''"
      >
        <fa-icon icon="i-far-xmark" />
      </app-button>
    </div>

    <div
      v-show="isOpenSearch && search.length > 0"
      :search="search"
      class="absolute inset-x-4 mt-2 border rounded-lg bg-white shadow lg:inset-x-0"
    >
      <search-result :search="search" />
    </div>
  </div>
</template>
