import { defineStore } from 'pinia'
import { useApi } from '@/functions/api'

export const useUserStore = defineStore('User', {
  state: (): AuthenticatedUser => ({
    id: 0,
    username: null,
    name: null,
    email: '',
    phone: null,
    birthday: null,
    photo_url: null,
    photo_url_xs: null,
    role: null,
    device_key: '',
    password_exists: false,
    social_media: [],
  }),
  getters: {
    hasRole: (state) => state.role !== null,
    profileIncomplete: (state) => state.name === null,
    userPhoto: (state) => {
      if (state.photo_url_xs) {
        return state.photo_url_xs
      }

      const socialMediaHasPhoto = state.social_media.find(
        (socialMedia) => socialMedia.avatar_url,
      )
      if (socialMediaHasPhoto) {
        return socialMediaHasPhoto.avatar_url
      }

      return null
    },
  },
  actions: {
    setDeviceKey(key: string) {
      this.device_key = key
      const api = useApi()
      api.POST('notification/device-key', { device_key: key })
    },
    init(user: AuthenticatedUser) {
      this.id = user.id
      this.username = user.username
      this.name = user.name
      this.email = user.email
      this.birthday = user.birthday
      this.phone = user.phone
      this.photo_url = user.photo_url
      this.photo_url_xs = user.photo_url_xs
      this.role = user.role
      this.password_exists = user.password_exists
      this.social_media = user.social_media
    },
    socialMediaDeleted(id: number) {
      this.social_media = this.social_media.filter((socialMedia) => socialMedia.id !== id)
    },
    setEmail(email: string) {
      this.email = email
    },
    setMissingData(name: string) {
      this.name = name
    },
    setPasswordExists() {
      this.password_exists = true
    },
  },
})
