<script setup lang="ts">
  import 'swiper/css'
  import 'swiper/css/free-mode'
  import 'swiper/css/navigation'
  import 'swiper/css/thumbs'
  import { Navigation } from 'swiper/modules'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { nextTick, ref } from 'vue'
  import VueEasyLightbox from 'vue-easy-lightbox'
  import { fmt } from '@/functions'
  import { useModal } from './modal'

  defineProps<{
    files: (File | Attachment | AttachmentUploading)[]
  }>()

  const src = (file: File | Attachment | AttachmentUploading) => {
    if ('progress' in file) {
      return ''
    }
    if ('filetype' in file === false) {
      // check if file is File
      // type File
      return URL.createObjectURL(file)
    }

    // type Video
    if (isFileVideo(file) && 'video' in file.url) {
      return file.url.video
    }

    // type Image
    if (isFileImage(file) && 'default' in file.url) {
      return file.url.default
    }

    // type Attachment other
    if ('default' in file.url) {
      return file.url.default
    }

    // undefined
    return ''
  }
  const filename = (file: File | Attachment) => {
    if ('filetype' in file === false) {
      // check if file is File
      // type File
      return file.name
    }
    // type Attachment
    return file.filename
  }

  const modules = [Navigation]

  const refSwiper = ref<InstanceType<typeof Swiper>>()

  const isFileImage = (file: File | Attachment) => {
    if ('filetype' in file === false) {
      // check if file is File
      return file.type.startsWith('image/')
    }
    // gif will be stored as other, so check it manually here
    if ('default' in file.url && file.url.default.endsWith('.gif')) {
      return true
    }
    return file.filetype === 'image'
  }
  const isFileVideo = (file: File | Attachment) => {
    if ('filetype' in file === false) {
      // check if file is File
      return file.type.startsWith('video/')
    }
    return file.filetype === 'video'
  }
  const isFileAudio = (file: File | Attachment) => {
    if ('filetype' in file === false) {
      // check if file is File
      return file.type.startsWith('audio/')
    }
    return file.filetype === 'audio'
  }
  const isFilePdf = (file: File | Attachment) => {
    if ('filetype' in file === false) {
      // check if file is File
      return file.name.endsWith('.pdf')
    }
    return file.filename.endsWith('.pdf')
  }
  const isFileCompressed = (file: File | Attachment) => {
    const extensions = ['.zip', '.rar', '.7z']
    if ('filetype' in file === false) {
      // check if file is File
      return extensions.some((ext) => file.name.endsWith(ext))
    }
    return extensions.some((ext) => file.filename.endsWith(ext))
  }

  const pauseAll = () => {
    const swiper = refSwiper.value?.$el.swiper
    swiper.slides.forEach((slide: HTMLElement) => {
      const el = slide.querySelector<HTMLMediaElement>('video,audio')
      el?.pause()
    })
  }

  const onSlideMounted = () => {
    setTimeout(() => {
      const swiper = refSwiper.value?.$el.swiper
      pauseAll()
      swiper.slides.forEach(async (slide: HTMLElement, index: number) => {
        const el = slide.querySelector<HTMLMediaElement>('video,audio')
        if (el && index === swiper.realIndex) {
          el.currentTime = 0
          try {
            await el.play()
            return
          } catch (error) {
            // ignore
            console.log(error)
          }
        }
      })
    }, 200)
  }

  const { isOpen } = useModal()
  const open = (index: number) => {
    isOpen.value = true
    nextTick(() => {
      refSwiper.value?.$el.swiper.slideTo(index)
    })
  }
  const close = () => {
    isOpen.value = false
    pauseAll()
  }

  defineExpose({ open })
</script>

<template>
  <teleport to="body">
    <transition name="fade">
      <div
        v-show="isOpen"
        class="fixed inset-0 z-50 bg-black bg-opacity-80"
      >
        <swiper
          ref="refSwiper"
          :style="{
            '--swiper-navigation-color': '#fff',
          }"
          :allow-touch-move="false"
          :loop="false"
          :space-between="0"
          :navigation="true"
          :modules="modules"
          class="size-full"
          @slide-change="onSlideMounted"
        >
          <swiper-slide
            v-for="(file, index) in files"
            :key="index"
            class="size-full items-center justify-center !flex"
          >
            <div v-if="'progress' in file"></div>
            <vue-easy-lightbox
              v-else-if="isFileImage(file)"
              class="!absolute"
              :visible="isOpen"
              :imgs="src(file)"
            >
              <template #close-btn>
                <div class="hidden"></div>
              </template>
            </vue-easy-lightbox>
            <div
              v-else-if="isFileVideo(file)"
              class="max-h-screen max-w-screen flex-center p-4 md:px-16 md:py-8"
            >
              <video controls>
                <source :src="src(file)" />
              </video>
            </div>
            <div
              v-else
              class="h-80 max-h-full max-w-full w-96 flex flex-col items-center justify-center bg-white p-4 text-center"
            >
              <div class="pt-2 text-6xl text-gray-600">
                <fa-icon
                  v-if="isFileCompressed(file)"
                  icon="i-fal-file-zip"
                />
                <fa-icon
                  v-else-if="isFilePdf(file)"
                  icon="i-fal-file-pdf"
                />
                <fa-icon
                  v-else-if="isFileAudio(file)"
                  icon="i-fal-file-audio"
                />
                <fa-icon
                  v-else
                  icon="i-fal-file"
                />
              </div>
              <div class="flex-center flex-1 flex-col gap-2">
                <p>{{ filename(file) }}</p>
                <p class="text-xs">{{ fmt.byte(file.size) }}</p>
              </div>
              <div
                v-if="isFileAudio(file)"
                class="bg-white py-4"
              >
                <audio controls>
                  <source :src="src(file)" />
                </audio>
              </div>
              <div>
                <a
                  class="btn-primary"
                  :href="src(file)"
                  download
                  :target="isFilePdf(file) ? '_blank' : undefined"
                >
                  <fa-icon icon="i-far-arrow-down-to-line" />
                  <span class="ml-2">Download</span>
                </a>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </transition>
    <transition name="fade">
      <app-button
        v-show="isOpen"
        class="fixed right-0 top-0 z-50 p-4 text-4xl text-white"
        title="Tutup"
        @click="close()"
      >
        <fa-icon icon="i-fal-xmark" />
      </app-button>
    </transition>
  </teleport>
</template>

<style lang="postcss" scoped>
  :deep() {
    .vel-modal {
      background: unset !important;
    }
    .vel-img {
      background-color: unset !important;
    }
  }
</style>
