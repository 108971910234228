import { type RouteRecordRaw } from 'vue-router'
import LayoutApp from '@/views/app/layout-app.vue'

const routes: RouteRecordRaw[] = [
  // Auth
  {
    path: '/auth',
    component: () => import('@/views/auth/layout-auth.vue'),
    children: [
      // {
      //   path: '/',
      //   name: 'welcome',
      //   component: () => import('@/views/app/welcome/page-welcome.vue'),
      //   meta: { title: 'Selamat Datang' },
      // },
      {
        path: '/login',
        component: () => import('@/views/auth/login/page-login.vue'),
        children: [
          {
            path: '',
            name: 'login',
            component: () => import('@/views/auth/login/page-login-select-method.vue'),
            meta: { title: 'Login' },
          },
          {
            path: 'email',
            name: 'login with email',
            component: () => import('@/views/auth/login/page-login-with-email.vue'),
            meta: { title: 'Login Menggunakan Email' },
          },
        ],
      },
      {
        path: '/lupa-password',
        name: 'forget password',
        component: () => import('@/views/auth/page-forget-password.vue'),
        meta: { title: 'Lupa Password' },
      },
      {
        path: '/daftar-baru',
        name: 'register',
        component: () => import('@/views/auth/register/page-register.vue'),
        meta: { title: 'Daftar Baru' },
      },
      {
        path: '/lengkapi-profil',
        name: 'register set missing data',
        component: () => import('@/views/auth/register/page-register-missing-data.vue'),
        meta: { title: 'Lengkapi Profil' },
      },
      // TODO create page for delete account
      // {
      //   path: '/hapus-data-pengguna',
      //   name: 'request delete user data',
      //   component: () => import('@/views/auth/page-request-delete-data.vue'),
      //   meta: { title: 'Minta Hapus Data' },
      // }
    ],
  },

  // App
  {
    path: '/app',
    component: LayoutApp,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/app/home/page-home.vue'),
        meta: { title: 'Beranda' },
      },
      {
        path: '/notifikasi',
        name: 'notification',
        component: () => import('@/views/app/notification/page-list.vue'),
        meta: { title: 'Notifikasi' },
      },
      {
        path: '/pengaturan',
        component: () => import('@/views/app/settings/layout-settings.vue'),
        meta: { title: 'Pengaturan' },
        children: [
          {
            path: '',
            name: 'settings',
            component: () => import('@/views/app/settings/profile/page-profile.vue'),
          },
          {
            path: 'profil',
            name: 'profile settings',
            component: () => import('@/views/app/settings/profile/page-profile.vue'),
            meta: { title: 'Profil Saya' },
          },
          {
            path: 'alamat',
            name: 'address settings',
            component: () => import('@/views/app/settings/address/page-address.vue'),
            meta: { title: 'Alamat Saya' },
          },
          {
            path: 'akun',
            name: 'account settings',
            component: () => import('@/views/app/settings/account/page-account.vue'),
            redirect: { name: 'account settings email' },
            children: [
              {
                path: 'email',
                name: 'account settings email',
                component: () => import('@/views/app/settings/account/page-email.vue'),
                meta: { title: 'Ganti Email' },
              },
              {
                path: 'password',
                name: 'account settings password',
                component: () => import('@/views/app/settings/account/page-password.vue'),
                meta: { title: 'Ganti Password' },
              },
              {
                path: 'social-login',
                name: 'account settings social login',
                component: () =>
                  import('@/views/app/settings/account/page-social-login.vue'),
                meta: { title: '3rd Party Login' },
              },
              {
                path: 'hapus-akun',
                name: 'account settings delete account',
                component: () =>
                  import('@/views/app/settings/account/page-delete-account.vue'),
                meta: { title: 'Hapus Akun Saya' },
              },
            ],
          },
          {
            path: '/versi-aplikasi',
            name: 'changelogs',
            component: () => import('@/views/app/settings/page-changelogs.vue'),
            meta: { title: 'Versi Aplikasi ' + __APP_VERSION__ },
          },
          // TODO create page for delete account
          // {
          //   path: 'hapus-akun',
          //   name: 'delete account',
          //   component: () => import('@/views/app/settings/page-delete-account.vue'),
          //   meta: { title: 'Hapus Akun' },
          // }
        ],
      },
      {
        path: '/jasa/:categories+', // match /one, /one/two, /one/two/three, etc.
        name: 'service',
        component: () => import('@/views/app/service/list/page-list.vue'),
        meta: { title: 'Layanan Jasa' },
        props: true,
      },
      {
        path: '/jasa/:id(\\d+)/:name?',
        name: 'service detail',
        component: () => import('@/views/app/service/detail/page-detail.vue'),
        meta: { title: 'Layanan Jasa' },
        props: true,
      },
      {
        path: '/proyek-saya',
        name: 'project list',
        component: () => import('@/views/app/project/list/page-list.vue'),
        meta: { title: 'Proyek Saya' },
      },
      {
        path: '/proyek-saya/:id/:name?',
        name: 'project detail',
        component: () => import('@/views/app/project/detail/page-detail.vue'),
        meta: { title: 'Detail Proyek' },
      },
      {
        path: '/request-layanan-jasa',
        name: 'project request create',
        component: () => import('@/views/app/project-request/create/page-create.vue'),
        meta: { title: 'Request Layanan Jasa' },
      },
      {
        path: '/request-layanan-jasa/edit/:id(\\d+)/:name?',
        name: 'project request edit',
        component: () => import('@/views/app/project-request/edit/page-edit.vue'),
        meta: { title: 'Edit Request Layanan Jasa' },
      },
      {
        path: '/request-layanan-jasa/:id(\\d+)/:name?',
        name: 'project request detail',
        component: () => import('@/views/app/project-request/detail/page-detail.vue'),
        meta: { title: 'Detail Request Layanan Jasa' },
      },
    ],
  },
  {
    path: '/produk',
    component: LayoutApp,
    children: [
      {
        path: ':categoryType(jual|sewa)',
        name: 'product list',
        props: true,
        component: () => import('@/views/app/product/list/page-list.vue'),
        meta: { title: 'Daftar Produk' }, // also set on page-list.vue
      },
      {
        path: ':categoryType(jual|sewa)/:id(\\d+)/:name?',
        name: 'product detail',
        props: true,
        component: () => import('@/views/app/product/detail/page-detail.vue'),
        meta: { title: 'Detail Produk' }, // also set on page-list.vue
      },
      {
        path: 'daftar-belanja',
        name: 'shopping list history',
        component: () => import('@/views/app/shopping-list/list/page-list.vue'),
        meta: { title: 'Daftar Belanja' },
      },
    ],
  },

  // Shopping List Detail
  {
    path: '/daftar-belanja/:id',
    name: 'shopping list detail',
    props: true,
    component: () => import('@/views/app/shopping-list/detail/page-detail.vue'),
    meta: { title: 'Detail Daftar Belanja' },
  },

  // Policy
  {
    path: '/policy',
    component: () => import('@/views/policy/layout-policy.vue'),
    children: [
      {
        path: '/syarat-ketentuan',
        name: 'terms condition',
        component: () => import('@/views/policy/page-terms-condition.vue'),
        meta: { title: 'Syarat dan Ketentuan' },
      },
      {
        path: '/kebijakan-privasi',
        name: 'privacy policy',
        component: () => import('@/views/policy/page-privacy-policy.vue'),
        meta: { title: 'Kebijakan Privasi' },
      },
    ],
  },

  // Not Found
  {
    path: '/:pathMatch(.*)*',
    component: LayoutApp,
    children: [
      {
        path: '',
        component: () => import('@/views/errors/error-404.vue'),
        meta: { title: 'Halaman Tidak Ditemukan' },
      },
    ],
  },
]

export default routes
