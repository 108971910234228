<script setup lang="ts">
  const emit = defineEmits<{
    (e: 'click'): void
  }>()
</script>

<template>
  <div class="absolute right-2 top-3 lg:right-4 lg:top-5">
    <button
      class="size-8 flex-center rounded-full text-gray-500 hover:bg-gray-200"
      @click="emit('click')"
    >
      <fa-icon icon="i-fas-xmark" />
    </button>
  </div>
</template>
