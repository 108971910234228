<script setup lang="ts">
  import DOMPurify from 'dompurify'
  import { computed } from 'vue'

  const props = defineProps<{ text: string }>()

  const content = computed(() => DOMPurify.sanitize(props.text.trim()))
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="vue-html"
    v-html="content"
  ></div>
</template>

<style lang="postcss">
  .vue-html {
    ul,
    ol {
      @apply ml-4 pl-6 text-sm;
    }

    ul {
      @apply list-disc;
    }

    ol {
      @apply list-decimal;
    }

    li {
      @apply my-2 pl-2;
    }

    p {
      @apply my-2 leading-6 text-sm;
    }

    a {
      @apply text-blue-400 underline;
    }

    strong {
      @apply font-bold;
    }

    td,
    th {
      @apply border px-2 py-1;
    }

    h1 {
      font-size: 200%;
      line-height: 200%;

      @apply font-bold;
    }

    h2 {
      font-size: 160%;
      line-height: 160%;

      @apply font-bold;
    }

    h3 {
      font-size: 125%;
      line-height: 125%;

      @apply font-bold;
    }

    h4 {
      font-size: 110%;
      line-height: 110%;

      @apply font-bold;
    }

    h5 {
      @apply font-bold;
    }

    h6 {
      @apply font-bold;
    }

    blockquote {
      @apply border-l-2 border-l-gray-300 pl-4;
    }

    hr {
      @apply my-2;
    }

    img {
      @apply max-w-64 max-h-64 w-full;
    }
  }
</style>
