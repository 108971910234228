<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    msg?: string | string[]
  }>()

  const text = computed(() => {
    // null / empty string / empty array
    if (!props.msg || props.msg.length === 0) {
      return null
    }

    // array
    if (Array.isArray(props.msg)) {
      return props.msg[0]
    }

    // string
    return props.msg
  })
</script>

<template>
  <div
    v-if="text"
    class="error-msg"
  >
    {{ text }}
  </div>
</template>

<style lang="postcss" scoped>
  /* the class is used in api.ts scrolltoview on error 422 */
  .error-msg {
    @apply rounded py-1 text-xs text-red-500;
    @apply scroll-mt-64; /* offset navbar top */
  }
</style>
