<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { debounce } from '@/functions'
  import { useAppSearchGlobal } from '@/models/app'
  import SearchResultProduct from './search-result-product.vue'
  import SearchResultService from './search-result-service.vue'
  import SearchResultWrapper from './search-result-wrapper.vue'

  const props = defineProps<{
    search: string
  }>()

  const { loading, rents, sells, services, getData, resetData } = useAppSearchGlobal()
  const debounceGetData = debounce(() => getData(props.search), 500)

  watch(
    () => props.search,
    () => {
      if (props.search) {
        loading.value = true
        debounceGetData()
        return
      }
      resetData()
    },
  )

  const groups = ref(['Semua', 'Jual', 'Jasa', 'Sewa'])
  const selected = ref('Semua')
</script>

<template>
  <div>
    <div
      class="scrollbar-none flex gap-2 border-b px-4 py-2 lg:flex-wrap max-lg:overflow-auto"
    >
      <button
        v-for="group in groups"
        :key="group"
        class="badge-navbar"
        :class="{ 'bg-primary text-white border-none': selected === group }"
        @click="selected = group"
      >
        {{ group }}
      </button>
    </div>

    <div class="max-h-96 overflow-auto py-2 divide-y">
      <div
        v-if="!search"
        class="search-empty"
      >
        Ketik untuk mencari produk / jasa...
      </div>
      <div
        v-else-if="loading"
        class="search-empty"
      >
        Tunggu sebentar
        <app-loading-dot />
      </div>
      <template v-else>
        <search-result-wrapper
          v-if="['Semua', 'Jual'].includes(selected)"
          title="Jual"
          :result-count="sells.length"
        >
          <search-result-product
            type="jual"
            :products="sells"
          />
        </search-result-wrapper>
        <search-result-wrapper
          v-if="['Semua', 'Jasa'].includes(selected)"
          title="Jasa"
          :result-count="services.length"
        >
          <search-result-service :services="services" />
        </search-result-wrapper>
        <search-result-wrapper
          v-if="['Semua', 'Sewa'].includes(selected)"
          title="Sewa"
          :result-count="rents.length"
        >
          <search-result-product
            type="sewa"
            :products="rents"
          />
        </search-result-wrapper>
      </template>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .badge-navbar {
    @apply flex-shrink-0 rounded border px-2 py-1 text-xs;
  }
  :deep() {
    .search-empty {
      @apply p-4 text-sm text-gray;
    }
  }
</style>
