<script setup lang="ts">
  import { toRef } from 'vue'
  import { fmt } from '@/functions'
  import { useProductVariantPrices } from '@/models/product'

  const props = defineProps<{
    product: HasPrice<Product>
  }>()

  const { minPrice, maxPrice } = useProductVariantPrices(toRef(() => props.product))
</script>

<template>
  <div class="text-xs">
    <!-- no variant -->
    <template v-if="product.variants.length === 0">
      <!-- product on sale -->
      <app-discount
        v-if="product.regular_price && typeof product.sale_price === 'number'"
        class="justify-center"
        :regular-price="product.regular_price"
        :sale-price="product.sale_price"
      />
      <div
        v-if="typeof product.price === 'number'"
        class="text-primary font-bold group-hover:text-white"
      >
        Rp {{ fmt.number(product.price) }}
      </div>
    </template>
    <!-- has variant -->
    <template v-else-if="minPrice === maxPrice">
      <div class="text-primary font-bold group-hover:text-white">
        Rp {{ fmt.number(minPrice) }}
      </div>
    </template>
    <template v-else>
      <div class="text-primary font-bold group-hover:text-white">
        Rp {{ fmt.number(minPrice) }} -
        {{ fmt.number(maxPrice) }}
      </div>
    </template>
  </div>
</template>
