import { useApi } from '@/functions/api'
import { useProductCategoryStore } from '@/stores/product-category'

export function useProductCategoryList() {
  const api = useApi()
  const productCategory = useProductCategoryStore()

  const getCategories = async () => {
    const response = await api.GET<ApiResponse<ProductCategory>>(`guest/product-category`)
    productCategory.setData(response.data)
    productCategory.setLoadingDone()
  }

  return {
    getCategories,
  }
}
