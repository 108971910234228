<script setup lang="ts">
  import TheToast from '@/components/the-toast.vue'
  import ErrorMaintenance from '@/views/errors/error-503.vue'
  import { useInit, useRedirectByAuthStatus } from './models/app'
  import { usePageStore } from './stores/page'

  const page = usePageStore()

  const { initApp } = useInit()
  initApp()

  useRedirectByAuthStatus()
</script>

<template>
  <div class="relative bg-white">
    <app-router-view />
  </div>
  <the-toast />
  <error-maintenance v-if="page.maintenance" />
</template>
