<script setup lang="ts">
  import { toRef } from 'vue'
  import { useAssertProductHasPrice } from '@/models/product'
  import ProductPrice from './search-result-product-price.vue'

  const props = defineProps<{
    type: 'sewa' | 'jual'
    product: Product
  }>()
  const { productHasPrice } = useAssertProductHasPrice(toRef(() => props.product))
</script>

<template>
  <router-link
    :key="product.id"
    :to="{
      name: 'product detail',
      params: { categoryType: type, id: product.id, name: product.name },
    }"
    class="group flex items-start px-4 py-2 space-x-4 hover:bg-primary hover:text-white"
  >
    <app-img
      :src="product.image_url"
      :alt="'product-' + product.name"
      class="size-14 flex-shrink-0 overflow-hidden border rounded bg-white"
    />
    <div>
      <div class="text-sm font-medium">
        {{ product.name }}
      </div>
      <product-price
        v-if="productHasPrice"
        :product="productHasPrice"
      />
    </div>
  </router-link>
</template>
