<script setup lang="ts">
  import { ref } from 'vue'
  import AppAttachmentPreview from '@/components/app-attachment-preview.vue'
  import AppAttachmentThumbnail from '@/components/app-attachment-thumbnail.vue'

  defineProps<{
    attachments: (File | Attachment | AttachmentUploading)[]
    disabled?: boolean
  }>()
  const emit = defineEmits<{
    (e: 'change', files: File[]): void
    (e: 'attachment:remove', index: number): void
  }>()
  const onFileChange = (files: File[]) => {
    emit('change', files)
  }
  const removeAttachment = (index: number) => {
    emit('attachment:remove', index)
  }
  const refPreview = ref<InstanceType<typeof AppAttachmentPreview>>()
</script>

<template>
  <div>
    <div class="space-y-4">
      <div
        v-if="attachments.length"
        class="flex flex-wrap gap-4"
      >
        <app-attachment-thumbnail
          v-for="(file, index) in attachments"
          :key="index"
          :file="file"
          :can-delete="!disabled"
          @remove="removeAttachment(index)"
          @click="refPreview?.open(index)"
        />
      </div>
      <div class="flex flex-wrap gap-4">
        <app-input-file
          v-if="!disabled"
          v-slot="{ open }"
          accept="image/*,video/*"
          capture="environment"
          @change="onFileChange"
        >
          <app-button
            class="btn-low-priority flex-shrink-0 space-x-2"
            @click="open()"
          >
            <fa-icon icon="i-far-camera" />
            <span>Buka Kamera</span>
          </app-button>
        </app-input-file>

        <app-input-file
          v-if="!disabled"
          v-slot="{ open }"
          multiple
          @change="onFileChange"
        >
          <app-button
            class="btn-low-priority flex-shrink-0 space-x-2"
            @click="open()"
          >
            <fa-icon icon="i-far-folder-open" />
            <span>Upload file</span>
          </app-button>
        </app-input-file>
      </div>

      <div class="text-xs max-lg:hidden">
        Bisa paste gambar, atau drag & drop file kesini
      </div>
    </div>

    <app-attachment-preview
      ref="refPreview"
      :files="attachments"
    />
  </div>
</template>
