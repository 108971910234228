import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useApi } from '@/functions/api'
import { usePageStore } from '@/stores/page'
import { useServiceStore } from '@/stores/service'

export function useServiceCategoryList() {
  const route = useRoute()
  const api = useApi()
  const service = useServiceStore()
  const page = usePageStore()

  const getDataCategories = async () => {
    try {
      service.setLoadingCategories()
      const response = await api.GET<ApiResponse<ServiceCategory>>(
        '/guest/service-category',
      )
      service.categories = response.data
    } finally {
      service.setLoadingCategoriesDone()
    }
  }

  const routeId = computed(() => {
    const routeCategories = route.params.categories as string[] | null
    return routeCategories?.slice(-1).pop()
  })

  const currentCategory = computed({
    get: () => {
      if (routeId.value) {
        return service.findCategoryByName(routeId.value)
      } else {
        return undefined
      }
    },
    set: (newValue: ServiceCategory | undefined) => {
      if (!routeId.value || !newValue) {
        return
      }

      const category = service.findCategoryByName(routeId.value)
      if (!category) {
        return
      }
      category.items = newValue.items
    },
  })

  const getDataServiceItems = async () => {
    if (!currentCategory.value) {
      // category is not loaded or params are not correct
      return
    }
    if (currentCategory.value.children.length > 0) {
      // not the most bottom category, should not have items
      return
    }
    try {
      // only show loading when there are no items yet
      if (currentCategory.value.items?.length === 0) {
        service.setLoadingItems()
      }
      const response = await api.GET<ApiResponse<ServiceItem>>('/guest/service', {
        service_category_id: currentCategory.value.id,
      })
      currentCategory.value = { ...currentCategory.value, items: response.data }
    } finally {
      service.setLoadingItemsDone()
    }
  }

  const setPageTitle = () => {
    page.setTitle(currentCategory.value?.name ?? 'Layanan Jasa')
  }

  return {
    currentCategory,
    getDataCategories,
    getDataServiceItems,
    setPageTitle,
  }
}

export function useServiceDetail() {
  const api = useApi()
  const currentService = ref<ServiceItemDetail>()
  const loading = ref(false)
  const getData = async (id: number | string) => {
    try {
      currentService.value = await api.GET<ServiceItemDetail>(`guest/service/${id}`)
    } finally {
      loading.value = false
    }
  }

  return {
    currentService,
    loading,
    getData,
  }
}

// export const serviceCategories: ServiceCategory[] = [
//   {
//     id: 'pekerjaan-struktur',
//     name: 'Pekerjaan \nStruktur',
//     icon_url: 'https://i.imgur.com/y4I6Ld3.png',
//     banner_url: 'https://i.imgur.com/9wWzp11.jpg',
//     children: [
//       {
//         id: 'bongkaran',
//         name: 'Bongkaran',
//         icon_url: 'https://i.imgur.com/rEYZjew.png',
//         banner_url: 'https://i.imgur.com/blyyOIT.jpg',
//         description:
//           'Pekerjaan bongkaran adalah proses pembongkaran atau penghancuran struktur bangunan yang ada, baik sebagian maupun seluruhnya, untuk tujuan renovasi, rekonstruksi, atau pembebasan lahan. Proses ini melibatkan pemindahan material bangunan seperti beton, kayu, logam, dan komponen lainnya dengan cara yang aman dan sesuai dengan standar keselamatan kerja. Pekerjaan bongkaran juga mencakup pengelolaan limbah konstruksi dan daur ulang material yang masih bisa digunakan, sehingga meminimalkan dampak lingkungan. Dalam konteks proyek konstruksi, pekerjaan bongkaran merupakan tahap awal yang krusial untuk memastikan bahwa area proyek siap untuk pembangunan berikutnya sesuai dengan rencana dan spesifikasi yang telah ditetapkan.',
//         items: [
//           {
//             name: 'Pembongkaran 1 m³ beton bertulang',
//             unit: 'm³',
//             price: 2_100_000,
//           },
//           {
//             name: 'Pembongkaran 1 m² beton bertulang',
//             unit: 'm²',
//             price: 250_000,
//           },
//           {
//             name: 'Pembongkaran 1 m² beton biasa',
//             unit: 'm²',
//             price: 150_000,
//           },
//           {
//             name: 'Pembongkaran 1 m³ dinding tembok bata',
//             unit: 'm³',
//             price: 100_000,
//           },
//           {
//             name: 'Pembongkaran 1 m³ pondasi batu kali',
//             unit: 'm³',
//             price: 350_000,
//           },
//           {
//             name: 'Pembongkaran 1 unit kusen pintu kayu uk. standard (1 x 2.1)',
//             unit: 'Unit',
//             price: 95_000,
//           },
//           // TODO lanjutkan
//         ],
//       },
//       {
//         id: 'tanah',
//         name: 'Tanah',
//         icon_url: 'https://i.imgur.com/BcdFEup.png',
//         banner_url: 'https://i.imgur.com/Lhm96Np.jpg',
//         description:
//           'Pekerjaan tanah dalam konstruksi merujuk pada semua aktivitas yang terkait dengan penggalian, pemadatan, pengurugan, dan perataan tanah untuk mempersiapkan dasar bangunan atau infrastruktur lainnya. Proses ini melibatkan pengukuran dan pemetaan tanah, penggalian lubang fondasi, pengangkutan tanah yang berlebih, serta pemadatan tanah untuk memastikan kestabilan dan kekuatan tanah dasar. Pekerjaan tanah juga mencakup pengelolaan air tanah, pengendalian erosi, dan penanganan tanah yang terkontaminasi, jika ada. Dengan demikian, pekerjaan tanah adalah bagian fundamental dari pembangunan yang mendukung kestabilan struktur dan keberlangsungan proyek konstruksi, memastikan bahwa fondasi yang dibangun kuat dan tahan lama.',
//         items: [
//           {
//             name: 'Galian tanah biasa',
//             unit: 'm³',
//             price: 135_000,
//           },
//           {
//             name: 'Galian tanah keras',
//             unit: 'm³',
//             price: 175_000,
//           },
//           {
//             name: 'Galian tanah lumpur',
//             unit: 'm³',
//             price: 225_000,
//           },
//           {
//             name: 'Galian tanah untuk Strauss',
//             unit: 'm¹',
//             price: 90_000,
//           },
//           {
//             name: 'Galian tanah untuk Bor Pile',
//             unit: 'm¹',
//             price: 105_000,
//           },
//           // TODO lanjutkan
//         ],
//       },
//       {
//         id: 'pondasi',
//         name: 'Pondasi',
//         icon_url: 'https://i.imgur.com/jPiVYi5.png',
//         banner_url: 'https://i.imgur.com/oRryxMS.jpg',
//         description:
//           'Pekerjaan pondasi adalah fase kritis dalam proyek konstruksi yang bertujuan untuk menciptakan dasar yang kokoh bagi bangunan. Proses ini melibatkan pembuatan struktur bawah tanah yang mendukung beban keseluruhan bangunan dan mendistribusikannya secara merata ke tanah di bawahnya. Jenis-jenis pondasi yang umum digunakan meliputi pondasi dangkal, seperti footings dan slab-on-grade, serta pondasi dalam, seperti tiang pancang dan caissons, tergantung pada kondisi tanah dan beban bangunan. Pekerjaan pondasi mencakup kegiatan seperti penggalian, pemasangan bekisting, pengecoran beton, dan penempatan penulangan baja. Kualitas dan ketepatan dalam pekerjaan pondasi sangat penting untuk mencegah masalah struktural di masa depan, seperti penurunan tanah dan keretakan bangunan. Oleh karena itu, pekerjaan pondasi harus dilakukan dengan perencanaan yang cermat dan pengawasan ketat untuk memastikan stabilitas dan keamanan bangunan yang akan didirikan.',
//         items: [
//           {
//             name: 'Pemasangan pondasi batu kali 1 m³',
//             unit: 'm³',
//             price: 625_000,
//           },
//           {
//             name: 'Pemasangan batu kosong (anstamping) 1 m³',
//             unit: 'm³',
//             price: 400_000,
//           },
//           {
//             name: 'Pemasangan pondasi bata rollag 1 m²',
//             unit: 'm²',
//             price: 225_000,
//           },
//         ],
//       },
//       {
//         id: 'besi-beton',
//         name: 'Besi & \nBeton',
//         icon_url: 'https://i.imgur.com/FgKX3zV.png',
//         banner_url: 'https://i.imgur.com/cB7u7qL.jpg',
//         description:
//           'Pekerjaan besi dan beton adalah tahap penting dalam konstruksi yang bertujuan untuk membangun kerangka struktural yang kuat dan tahan lama bagi bangunan. Pekerjaan ini melibatkan pembuatan elemen beton bertulang, yang terdiri dari campuran beton dan tulangan besi untuk meningkatkan kekuatan tarik beton. Proses ini mencakup beberapa langkah utama, seperti pemasangan bekisting untuk membentuk beton, pemasangan tulangan besi sesuai dengan desain struktural, dan pengecoran beton ke dalam bekisting. Setelah beton dituangkan, diperlukan waktu curing untuk mencapai kekuatan maksimum. Pekerjaan besi dan beton harus dilakukan dengan presisi tinggi, mengikuti spesifikasi teknis dan standar keselamatan yang ketat, untuk memastikan bahwa struktur bangunan dapat menahan beban dan tekanan yang akan dihadapinya selama masa pakai. Kualitas pengerjaan dan bahan yang digunakan dalam pekerjaan besi dan beton sangat menentukan ketahanan dan stabilitas keseluruhan bangunan.',
//       },
//     ],
//   },
//   {
//     id: 'pasangan-bata',
//     name: 'Pasangan \nBata',
//     icon_url: 'https://i.imgur.com/1BZxjXX.png',
//     banner_url: 'https://i.imgur.com/46FE5F6.jpg',
//     children: [
//       {
//         id: 'pasang-dinding-partisi',
//         name: 'Pasang Dinding \n& Partisi',
//         icon_url: 'https://i.imgur.com/1BZxjXX.png',
//         banner_url: 'https://i.imgur.com/46FE5F6.jpg',
//         description:
//           'Pemasangan dinding dan partisi adalah tahap konstruksi yang bertujuan untuk membentuk ruang-ruang dalam bangunan sesuai dengan desain arsitektur. Proses ini melibatkan pemasangan berbagai jenis material, seperti bata, blok beton, gypsum, atau panel kayu, yang dipilih berdasarkan fungsi, estetika, dan anggaran proyek. Pekerjaan ini dimulai dengan pengukuran dan penandaan lokasi dinding atau partisi, diikuti oleh pembangunan struktur dasar dan penyusunan material dinding atau partisi. Khusus untuk dinding bata atau blok beton, pekerjaan melibatkan pemasangan mortar dan penyusunan material secara berlapis, sementara untuk partisi gypsum atau panel kayu, biasanya melibatkan rangka logam atau kayu yang kemudian dilapisi dengan panel. Pemasangan dinding dan partisi harus dilakukan dengan ketelitian untuk memastikan kekuatan, kestabilan, serta keselarasan dengan elemen struktural dan sistem bangunan lainnya, seperti instalasi listrik dan pipa. Selain itu, pekerjaan ini juga memperhatikan isolasi suara dan termal untuk kenyamanan penghuni bangunan.',
//       },
//       {
//         id: 'plester-acian',
//         name: 'Plester & \nAcian',
//         icon_url: 'https://i.imgur.com/JLoeyyN.png',
//         banner_url: 'https://i.imgur.com/gaEy5kl.jpg',
//         description:
//           'Plesteran dan acian adalah tahap finishing dalam konstruksi yang bertujuan untuk menghasilkan permukaan dinding dan plafon yang halus, rata, dan siap untuk proses pengecatan atau pemasangan lapisan akhir lainnya. Plesteran melibatkan penerapan campuran semen, pasir, dan air pada permukaan dinding atau plafon untuk menutupi dan meratakan permukaan kasar, seperti bata atau beton. Proses ini dimulai dengan pembersihan permukaan, pemasangan lapisan dasar plester, dan kemudian pelapisan serta perataan campuran plester dengan alat khusus seperti sekop dan mistar. Setelah plesteran selesai dan mengering, langkah berikutnya adalah acian, yaitu penerapan lapisan tipis campuran semen dan air pada permukaan plesteran untuk mendapatkan hasil akhir yang lebih halus dan lebih rata. Acian dilakukan dengan menggunakan roskam atau alat penggosok khusus, memastikan bahwa tidak ada lubang atau retakan kecil yang tersisa. Kedua tahap ini sangat penting untuk memperbaiki tampilan estetika dinding dan plafon serta mempersiapkan permukaan untuk pengecatan atau pelapisan akhir lainnya, memastikan hasil akhir yang berkualitas dan tahan lama.',
//       },
//     ],
//   },
//   {
//     id: 'rangka-baja-atap',
//     name: 'Rangka Baja\n& Atap',
//     icon_url: 'https://i.imgur.com/5NtprmY.png',
//     banner_url: 'https://i.imgur.com/9qXgee7.jpg',
//     children: [
//       {
//         id: 'rangka-baja',
//         name: 'Rangka \nBaja',
//         icon_url: 'https://i.imgur.com/5NtprmY.png',
//         banner_url: 'https://i.imgur.com/9qXgee7.jpg',
//         description:
//           'Rangka baja adalah kerangka struktural yang terbuat dari komponen baja dan digunakan sebagai tulang punggung untuk mendukung bangunan atau struktur lainnya. Proses pemasangan rangka baja melibatkan perakitan elemen-elemen baja seperti balok, kolom, dan rangka atap yang telah diproduksi dan dipotong sesuai spesifikasi desain. Baja dipilih karena kekuatannya yang tinggi, daya tahan, dan fleksibilitas dalam aplikasi arsitektural yang kompleks. Pemasangan rangka baja dimulai dengan pembuatan fondasi yang kuat, diikuti oleh erecting atau pendirian kolom dan balok utama, dan kemudian penyambungan elemen-elemen tersebut dengan baut, paku keling, atau pengelasan. Ketepatan dalam pemasangan dan penyambungan sangat penting untuk memastikan kestabilan dan keamanan struktur keseluruhan. Selain itu, rangka baja sering kali dilapisi dengan bahan anti karat dan tahan api untuk meningkatkan umur panjang dan keselamatan. Penggunaan rangka baja memungkinkan konstruksi yang lebih cepat dan efisien, serta memberikan fleksibilitas desain yang besar, terutama untuk bangunan bertingkat tinggi dan struktur industri.',
//       },
//       {
//         id: 'penutup-atap',
//         name: 'Penutup \nAtap',
//         icon_url: 'https://i.imgur.com/lshmlic.png',
//         banner_url: 'https://i.imgur.com/nHeadDo.jpg',
//         description:
//           'Penutup atap adalah elemen penting dalam konstruksi bangunan yang berfungsi melindungi struktur di bawahnya dari cuaca ekstrem, seperti hujan, panas, dan angin. Proses pemasangan penutup atap melibatkan pemilihan dan pemasangan material yang tepat sesuai dengan desain arsitektural dan kebutuhan fungsional bangunan. Material yang umum digunakan untuk penutup atap meliputi genteng tanah liat, genteng beton, lembaran metal, aspal, serta bahan-bahan modern seperti atap polikarbonat dan atap komposit. Tahap pemasangan dimulai dengan persiapan rangka atap yang kokoh, diikuti oleh pemasangan underlayment atau lapisan kedap air untuk tambahan perlindungan, dan kemudian pemasangan material penutup atap sesuai pola dan metode yang disarankan oleh pabrikannya. Penutup atap harus dipasang dengan teliti untuk memastikan tidak ada kebocoran dan untuk menjamin umur panjang dari atap tersebut. Selain melindungi dari elemen cuaca, penutup atap juga berperan dalam estetika keseluruhan bangunan dan berkontribusi pada efisiensi energi dengan memberikan insulasi termal.',
//       },
//     ],
//   },
//   {
//     id: 'plumbing',
//     name: 'Plumbing',
//     icon_url: 'https://i.imgur.com/jBvHboK.png',
//     banner_url: 'https://i.imgur.com/7mpICOX.jpg',
//     description:
//       'Plumbing adalah sistem instalasi yang bertujuan untuk mendistribusikan air bersih dan mengalirkan air limbah dalam bangunan, mencakup perencanaan, pemasangan, dan pemeliharaan pipa serta peralatan terkait. Proses plumbing dimulai dengan perancangan jaringan pipa yang efisien dan sesuai dengan peraturan standar kesehatan dan keselamatan. Instalasi plumbing melibatkan pemasangan pipa-pipa utama dan cabang untuk distribusi air bersih dari sumber, seperti sumur atau jaringan air kota, serta pipa-pipa pembuangan yang mengalirkan air limbah ke sistem pembuangan atau septik tank. Selain itu, plumbing juga mencakup pemasangan peralatan seperti keran, wastafel, toilet, shower, dan sistem pemanas air. Kualitas dan ketepatan dalam pekerjaan plumbing sangat penting untuk memastikan sistem berjalan lancar tanpa kebocoran atau penyumbatan, serta untuk mencegah kontaminasi air bersih. Plumbing yang baik berkontribusi pada kenyamanan dan kesehatan penghuni bangunan dengan menyediakan akses air bersih yang aman dan sistem pembuangan yang efektif.',
//   },
//   {
//     id: 'sanitary',
//     name: 'Sanitary',
//     icon_url: 'https://i.imgur.com/CCKPrz2.png',
//     banner_url: 'https://i.imgur.com/7mpICOX.jpg',
//     description:
//       'Sanitary adalah sistem sanitasi dalam konstruksi bangunan yang dirancang untuk mengelola dan mengolah air limbah serta limbah padat secara higienis dan efisien, guna menjaga kebersihan dan kesehatan lingkungan. Proses ini mencakup instalasi pipa pembuangan, tangki septik, saluran air hujan, serta perangkat pengolahan limbah lainnya. Sistem sanitary bertujuan untuk memastikan bahwa air limbah dari berbagai sumber, seperti kamar mandi, dapur, dan area mencuci, dialirkan dengan benar ke fasilitas pengolahan atau pembuangan akhir tanpa mencemari sumber air bersih dan lingkungan sekitar. Pemasangan sistem sanitary melibatkan pemilihan material yang tahan korosi dan kebocoran, seperti PVC atau besi galvanis, serta pemasangan perangkat tambahan seperti jebakan lemak dan unit pengolahan air limbah. Perencanaan dan pelaksanaan sistem sanitary yang baik sangat penting untuk mencegah masalah seperti penyumbatan, kebocoran, dan bau tidak sedap, yang dapat mengganggu kenyamanan dan kesehatan penghuni bangunan. Dengan demikian, sistem sanitary yang efektif dan efisien memainkan peran krusial dalam menjaga standar kebersihan dan kesehatan dalam lingkungan bangunan.',
//   },
//   {
//     id: 'plafon',
//     name: 'Plafon',
//     icon_url: 'https://i.imgur.com/iv2Huce.png',
//     banner_url: 'https://i.imgur.com/GifUgk1.jpg',
//     description:
//       'Plafon adalah bagian dari struktur atap dalam sebuah bangunan yang terletak di bawah struktur atap utama dan digunakan untuk menutupi rangka atap serta memberikan tampilan estetika yang rapi dan selesai pada ruang di bawahnya. Plafon dapat terbuat dari berbagai material, seperti gypsum board, kayu, logam, atau bahan lainnya, yang dipilih berdasarkan kebutuhan fungsional, estetika, dan anggaran proyek. Proses instalasi plafon dimulai dengan pemasangan rangka penyangga yang biasanya terbuat dari kayu atau logam, yang kemudian ditutupi dengan material plafon. Tahap ini meliputi pemasangan dan penyesuaian panel plafon atau lembaran sesuai dengan ukuran dan desain ruangan. Selain memberikan tampilan yang bersih dan rapi, plafon juga berperan dalam isolasi akustik dan termal serta menyembunyikan instalasi seperti kabel dan pipa yang berjalan di atasnya. Plafon yang dipilih dan dipasang dengan baik tidak hanya meningkatkan nilai estetika ruangan, tetapi juga meningkatkan kenyamanan dan fungsionalitas ruang di dalam bangunan.',
//   },
//   {
//     id: 'lantai-keramik',
//     name: 'Lantai \nKeramik',
//     icon_url: 'https://i.imgur.com/hozAqc3.png',
//     banner_url: 'https://i.imgur.com/cxBCEIc.jpg',
//     description:
//       'Lantai keramik adalah jenis lantai yang terbuat dari piringan atau papan-papan yang dipadatkan dan dikenai panas hingga membentuk bahan yang keras dan tahan lama. Lantai ini terdiri dari campuran tanah liat, pasir, dan bahan lain yang dipadatkan dan dipanaskan dalam proses pembuatan, lalu ditutupi dengan glasur atau warna untuk memberikan keindahan dan daya tahan tambahan. Lantai keramik sangat populer karena kekuatannya yang tinggi terhadap air, keausan, dan noda, serta kemampuannya untuk menjaga kebersihan dan estetika dalam berbagai lingkungan, mulai dari rumah tangga hingga area komersial. Proses pemasangan lantai keramik melibatkan persiapan permukaan yang rata dan kering, penggunaan lem atau adukan untuk menempelkan piringan keramik ke lantai, dan grouting untuk mengisi celah antar piringan dan memperkuat struktur keseluruhan.',
//   },
//   {
//     id: 'kusen-pintu-jendela',
//     name: 'Kusen, Pintu \n& Jendela',
//     icon_url: 'https://i.imgur.com/fWwvv4r.png',
//     banner_url: 'https://i.imgur.com/EEO7dyV.jpg',
//     description:
//       'Kusen, pintu, dan jendela merupakan komponen penting dalam struktur bangunan yang berperan dalam keamanan, isolasi termal dan akustik, serta estetika ruang. Kusen adalah bingkai atau rangka yang dipasang di sekitar pintu atau jendela untuk menopang dan menahan beban struktur. Kusen biasanya terbuat dari kayu, baja, aluminium, atau bahan komposit lainnya, tergantung pada kebutuhan dan desain bangunan. Pintu adalah panel yang dapat dibuka dan ditutup untuk memberikan akses masuk dan keluar dari ruangan atau bangunan. Pintu tersedia dalam berbagai jenis, seperti pintu kayu, pintu baja, atau pintu aluminium, dengan variasi desain dan keamanan yang berbeda. Jendela adalah bukaan pada dinding bangunan yang dilengkapi dengan kaca atau bahan transparan lainnya untuk memungkinkan cahaya masuk ke dalam ruangan serta sirkulasi udara. Jendela juga hadir dalam berbagai jenis, seperti jendela geser, jendela casement, atau jendela awning, yang dipilih berdasarkan kebutuhan fungsional dan estetika ruang yang bersangkutan. Pemasangan kusen, pintu, dan jendela dilakukan dengan presisi tinggi untuk memastikan keselarasan dengan struktur bangunan dan memberikan fungsi optimal dalam hal keamanan, kenyamanan, dan efisiensi energi.',
//   },
//   {
//     id: 'kelistrikan',
//     name: 'Kelistrikan',
//     icon_url: 'https://i.imgur.com/wgbVOoH.png',
//     banner_url: 'https://i.imgur.com/wAMZuPN.jpg',
//     description:
//       'Kelistrikan adalah sistem yang penting dalam konstruksi bangunan yang bertanggung jawab untuk menyediakan tenaga listrik untuk berbagai keperluan, seperti penerangan, peralatan elektronik, dan sistem komunikasi. Proses instalasi kelistrikan melibatkan perencanaan dan pemasangan kabel, sakelar, stop kontak, dan perlengkapan lainnya yang terhubung dengan panel listrik utama. Panel listrik ini berfungsi sebagai pusat distribusi untuk mengatur dan mengontrol aliran listrik ke berbagai bagian bangunan. Instalasi kelistrikan harus mematuhi standar keselamatan dan peraturan setempat untuk mencegah kebakaran, kebocoran arus listrik, dan gangguan listrik lainnya. Selain itu, instalasi kelistrikan juga harus dirancang dengan mempertimbangkan kapasitas beban yang diperlukan serta efisiensi energi untuk mendukung kebutuhan operasional bangunan dengan optimal. Dengan teknologi yang terus berkembang, sistem kelistrikan modern juga sering dilengkapi dengan fitur-fitur seperti proteksi terhadap lonjakan tegangan dan otomatisasi untuk meningkatkan keamanan dan kenyamanan penggunaan listrik dalam bangunan.',
//   },
// ]

// export const serviceCategoryPaint: ServiceCategoryPaint = {
//   id: 'finishing-cat',
//   name: 'Finishing \nCat',
//   icon_url: 'https://i.imgur.com/LSSrP8L.png',
//   banner_url: 'https://i.imgur.com/nXtad79.jpeg',
//   children: [
//     {
//       id: 'ducco',
//       name: 'Ducco',
//       icon_url: 'https://i.imgur.com/LgSaKnl.png',
//       banner_url: 'https://i.imgur.com/oRG6vAj.jpg',
//       children: [
//         {
//           id: 'cat-finishing',
//           name: 'Cat Finishing',
//           icon_url: '',
//           banner_url: '',
//           children: [
//             {
//               id: 'dulux',
//               name: 'Dulux',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Dulux Catylac Interior',
//                   short_description:
//                     'Dulux Catylac Interior dengan formulai Chroma Brite Technology untuk warna dinding cerah menakjubkan lebih lama.',
//                   thumbnail_url:
//                     'https://i0.wp.com/solusibangunan.id/wp-content/uploads/2023/06/Dulux-Catylac-Interior-1-min.jpg?ssl=1&resize=200%2C200',
//                   price: 100_000,
//                 },
//                 {
//                   name: 'Dulux Pentalite',
//                   short_description:
//                     'Dulux Pentalite menghadirkan sentuhan maksimal dengan kualitas ProCover TechnologyTM dengan fungsi utama daya sebar maksimal, hasil akhir menawan dan warna tahan lama.',
//                   thumbnail_url:
//                     'https://i0.wp.com/solusibangunan.id/wp-content/uploads/2023/06/Dulux-Pentalite-1-min.jpg?ssl=1&resize=200%2C200',
//                   price: 150_000,
//                 },
//                 {
//                   name: 'Dulux Weathershield',
//                   short_description:
//                     'Dulux Weathershield adalah cat bermutu tinggi khusus untuk eksterior, berbahan dasar air.',
//                   thumbnail_url:
//                     'https://i0.wp.com/solusibangunan.id/wp-content/uploads/2023/06/Dulux-Weathershield-1-min.jpg?ssl=1&resize=200%2C200',
//                   price: 200_000,
//                 },
//               ],
//             },
//             {
//               id: 'propan',
//               name: 'Propan',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'nippon',
//               name: 'Nippon',
//               icon_url: '',
//               banner_url: '',
//             },
//           ],
//         },
//         {
//           id: 'waterproofing',
//           name: 'Waterproofing',
//           icon_url: '',
//           banner_url: '',
//         },
//         {
//           id: 'lain-lain',
//           name: 'Lain-lain',
//           icon_url: '',
//           banner_url: '',
//         },
//       ],
//     },
//     {
//       id: 'cat-lantai',
//       name: 'Cat \nLantai',
//       icon_url: 'https://i.imgur.com/PC9bBgb.png',
//       banner_url: 'https://i.imgur.com/l238776.jpg',
//       children: [
//         {
//           id: 'cat-finishing',
//           name: 'Cat Finishing',
//           icon_url: '',
//           banner_url: '',
//           children: [
//             {
//               id: 'jotun',
//               name: 'Jotun',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Jotafloor (100 Micron Interior)',
//                   short_description:
//                     'Jotafloor adalah cat lantai berbasis epoksi yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior yang membutuhkan ketahanan terhadap lalu lintas ringan hingga sedang.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 226_000,
//                 },
//               ],
//             },
//             {
//               id: 'propan',
//               name: 'Propan',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Polyfloor Epoxy SB PFT-253-2K SB (500 Micron)',
//                   short_description:
//                     'Polyfloor Epoxy SB PFT-253-2K SB adalah cat lantai berbasis epoksi yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior dan eksterior yang membutuhkan ketahanan terhadap lalu lintas sedang hingga berat.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 442_000,
//                 },
//                 {
//                   name: 'Polyfloor Epoxy SB PFT-253-2K SB (300 Micron)',
//                   short_description:
//                     'Polyfloor Epoxy SB PFT-253-2K SB adalah cat lantai berbasis epoksi yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior dan eksterior yang membutuhkan ketahanan terhadap lalu lintas sedang hingga berat.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 344_000,
//                 },
//               ],
//             },
//             {
//               id: 'sika',
//               name: 'Sika',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Sikacrete Floor Level',
//                   short_description:
//                     'Sikacrete Floor Level adalah mortar finishing untuk lantai beton yang cepat kering dan tahan lama, cocok untuk aplikasi interior dan eksterior.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 131_000,
//                 },
//                 {
//                   name: 'Sika Tilegrout',
//                   short_description:
//                     'Sika Tilegrout adalah material untuk mengisi celah dan retak pada keramik dan batu alam, tahan air dan tahan lama.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 77_000,
//                 },
//               ],
//             },
//             {
//               id: 'avian',
//               name: 'Avian',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Avian Cat Paving',
//                   short_description:
//                     'Avian Cat Paving adalah cat lantai berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi eksterior yang membutuhkan ketahanan terhadap lalu lintas berat.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 141_000,
//                 },
//                 {
//                   name: 'Avian Roadline Paint',
//                   short_description:
//                     'Avian Roadline Paint adalah cat lantai berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk marking jalan raya dan parkir.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 148_000,
//                 },
//               ],
//             },
//             {
//               id: 'am',
//               name: 'AM',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'AM 50 (NAT)',
//                   short_description:
//                     'AM 50 adalah cat lantai berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior yang membutuhkan ketahanan terhadap lalu lintas ringan hingga sedang.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 116_000,
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//     {
//       id: 'wood-finishing',
//       name: 'Wood \nFinishing',
//       icon_url: 'https://i.imgur.com/Em2reNY.png',
//       banner_url: 'https://i.imgur.com/h0boocW.jpg',
//       children: [
//         {
//           id: 'cat-finishing',
//           name: 'Wood Finishing',
//           icon_url: '',
//           banner_url: '',
//           children: [
//             {
//               id: 'melamine-system',
//               name: 'Melamine System',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'ML-131 (Doff, Gloss, Semi Gloss)',
//                   short_description:
//                     'ML-131 adalah cat melamine berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk finishing kayu interior dan furniture.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 564_000,
//                 },
//               ],
//             },
//             {
//               id: 'nitro-cellulose-system',
//               name: 'Nitro Cellulose System',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'NC-141 (Doff, Gloss, Semi Gloss)',
//                   short_description:
//                     'NC-141 adalah cat nitro cellulose berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk finishing kayu interior dan furniture.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 563_000,
//                 },
//               ],
//             },
//             {
//               id: 'polyurethane-system',
//               name: 'Polyurethane System',
//               icon_url: '',
//               banner_url: '',
//               items: [],
//             },
//             {
//               id: 'aqua-politur',
//               name: 'Aqua Politur',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Aqua Politur',
//                   short_description:
//                     'Aqua Politur adalah bahan finishing kayu berbasis air yang tahan lama dan mudah diaplikasikan, cocok untuk finishing kayu interior dan furniture.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 226_000,
//                 },
//                 {
//                   name: 'Aqua Politur Clear (Doff, Gloss)',
//                   short_description:
//                     'Aqua Politur Clear adalah bahan finishing kayu berbasis air yang tahan lama dan mudah diaplikasikan, cocok untuk finishing kayu interior dan furniture.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 226_000,
//                 },
//                 {
//                   name: 'Fiberkote FBK-888 WB Gloss/FBK-889 WB Dof Warna Standar',
//                   short_description:
//                     'AM 151 adalah cat lantai berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior yang membutuhkan ketahanan terhadap lalu lintas ringan hingga sedang.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 288_000,
//                 },
//                 {
//                   name: 'Alkycoat Deco Woodstain',
//                   short_description:
//                     'Alkycoat Deco Woodstain adalah cat lantai berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior yang membutuhkan ketahanan terhadap lalu lintas ringan hingga sedang.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 286_000,
//                 },
//               ],
//             },
//             {
//               id: 'parquet-lack',
//               name: 'Parquet Lack',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'UPL-77 E Gloss',
//                   short_description:
//                     'UPL-77 E Gloss adalah cat parquet berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk finishing lantai kayu.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 626_000,
//                 },
//                 {
//                   name: 'UPL-77 E Doff',
//                   short_description:
//                     'UPL-77 E Doff adalah cat parquet berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk finishing lantai kayu.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 636_000,
//                 },
//               ],
//             },
//             {
//               id: 'multipox',
//               name: 'Multipox',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Propan Synthetic 3000 SB',
//                   short_description:
//                     'Propan Synthetic 3000 SB adalah cat lantai berbasis epoksi yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior dan eksterior yang membutuhkan ketahanan terhadap lalu lintas sedang hingga berat.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 260_000,
//                 },
//                 {
//                   name: 'Gardex Black 0099',
//                   short_description:
//                     'Gardex Black 0099 adalah cat lantai berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior dan eksterior yang membutuhkan ketahanan terhadap lalu lintas ringan hingga sedang.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 294_000,
//                 },
//                 {
//                   name: 'AM 151',
//                   short_description:
//                     'AM 151 adalah cat lantai berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk aplikasi interior yang membutuhkan ketahanan terhadap lalu lintas ringan hingga sedang.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 153_000,
//                 },
//               ],
//             },
//             {
//               id: 'exterior-lasur-system',
//               name: 'Exterior Lasur System',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'EL-501',
//                   short_description:
//                     'EL-501 adalah lasur berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk finishing kayu eksterior.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 476_000,
//                 },
//                 {
//                   name: 'EL-501 Clear (Doff, Gloss, Satin)',
//                   short_description:
//                     'EL-501 Clear adalah lasur berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk finishing kayu eksterior.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 453_000,
//                 },
//                 {
//                   name: 'EDL-601',
//                   short_description:
//                     'EDL-601 adalah lasur berbasis akrilik yang tahan lama dan mudah dibersihkan, cocok untuk finishing kayu eksterior.',
//                   thumbnail_url: 'https://placehold.co/80',
//                   price: 438_000,
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//     {
//       id: 'cat-tembok-gold',
//       name: 'Cat Tembok \nGold',
//       icon_url: 'https://i.imgur.com/yduZ67Y.png',
//       banner_url: 'https://i.imgur.com/lyCj5Rq.jpeg',
//       children: [
//         {
//           id: 'cat-finishing',
//           name: 'Cat Finishing',
//           icon_url: '',
//           banner_url: '',
//           children: [
//             {
//               id: 'dulux',
//               name: 'Dulux',
//               icon_url: '',
//               banner_url: '',
//               items: [
//                 {
//                   name: 'Dulux Catylac Interior',
//                   short_description:
//                     'Dulux Catylac Interior dengan formulai Chroma Brite Technology untuk warna dinding cerah menakjubkan lebih lama.',
//                   thumbnail_url:
//                     'https://i0.wp.com/solusibangunan.id/wp-content/uploads/2023/06/Dulux-Catylac-Interior-1-min.jpg?ssl=1&resize=200%2C200',
//                   price: 100_000,
//                 },
//                 {
//                   name: 'Dulux Pentalite',
//                   short_description:
//                     'Dulux Pentalite menghadirkan sentuhan maksimal dengan kualitas ProCover TechnologyTM dengan fungsi utama daya sebar maksimal, hasil akhir menawan dan warna tahan lama.',
//                   thumbnail_url:
//                     'https://i0.wp.com/solusibangunan.id/wp-content/uploads/2023/06/Dulux-Pentalite-1-min.jpg?ssl=1&resize=200%2C200',
//                   price: 150_000,
//                 },
//                 {
//                   name: 'Dulux Weathershield',
//                   short_description:
//                     'Dulux Weathershield adalah cat bermutu tinggi khusus untuk eksterior, berbahan dasar air.',
//                   thumbnail_url:
//                     'https://i0.wp.com/solusibangunan.id/wp-content/uploads/2023/06/Dulux-Weathershield-1-min.jpg?ssl=1&resize=200%2C200',
//                   price: 200_000,
//                 },
//               ],
//             },
//             {
//               id: 'jotun',
//               name: 'Jotun',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'propan',
//               name: 'Propan',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'mowilex',
//               name: 'Mowilex',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'nippon',
//               name: 'Nippon',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'alkycoat',
//               name: 'Alkycoat',
//               icon_url: '',
//               banner_url: '',
//             },
//           ],
//         },
//         {
//           id: 'waterproofing',
//           name: 'Waterproofing',
//           icon_url: '',
//           banner_url: '',
//           children: [
//             {
//               id: 'sika',
//               name: 'Sika',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'propan',
//               name: 'Propan',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'lemkra',
//               name: 'Lemkra',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'pentens',
//               name: 'Pentens',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'dulux',
//               name: 'Dulux',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'aqua-proof',
//               name: 'Aqua Proof',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'no-drop',
//               name: 'No Drop',
//               icon_url: '',
//               banner_url: '',
//             },
//             {
//               id: 'gamma-paint',
//               name: 'Gamma Paint',
//               icon_url: '',
//               banner_url: '',
//             },
//           ],
//         },
//         {
//           id: 'lain-lain',
//           name: 'Lain-lain',
//           icon_url: '',
//           banner_url: '',
//           items: [
//             {
//               name: 'Duracoat W300 Interior',
//               short_description:
//                 'Duracoat W300 Interior adalah cat tembok berbahan dasar air yang memiliki daya sebar tinggi dan tahan lama.',
//               thumbnail_url: 'https://placehold.co/80',
//               price: 96_000,
//             },
//             {
//               name: 'Duracoat SC300',
//               short_description:
//                 'Duracoat SC300 adalah cat tembok berbahan dasar air yang memiliki daya sebar tinggi dan tahan lama',
//               thumbnail_url: 'https://placehold.co/80',
//               price: 97_000,
//             },
//             {
//               name: 'Skimcoat SC 200 Uzin',
//               short_description:
//                 'Skimcoat SC 200 Uzin adalah cat tembok berbahan dasar air yang memiliki daya sebar tinggi dan tahan lama',
//               thumbnail_url: 'https://placehold.co/80',
//               price: 96_000,
//             },
//           ],
//         },
//       ],
//     },
//   ],
// }
