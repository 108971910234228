<script setup lang="ts">
  import { Menu as AppMenu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { useLogout } from '@/models/app'
  import { usePageStore } from '@/stores/page'
  import { useUserStore } from '@/stores/user'

  defineProps<{
    status: 'the-navbar-upper' | 'the-menu-bar'
  }>()

  const route = useRoute()
  const user = useUserStore()
  const page = usePageStore()

  const logout = useLogout()

  const openingUserRoutes = computed(() => {
    if (route.fullPath.startsWith('/pengaturan')) {
      return true
    }
    if (route.name === 'notification') {
      return true
    }
    if (route.name === 'shopping list history') {
      return true
    }
    if (route.name === 'project list') {
      return true
    }
    return false
  })

  // TODO count ongoing projects
  // TODO shopping cart
</script>

<template>
  <app-menu
    v-slot="{ open }"
    as="div"
    class="inline-block sm:relative"
  >
    <template v-if="status === 'the-navbar-upper'">
      <menu-button title="Klik untuk menampilkan menu">
        <span class="font-bold">
          {{ user.name }}
        </span>
      </menu-button>
    </template>

    <template v-else>
      <menu-button
        title="Klik untuk menampilkan menu"
        class="menu-bar-item"
        :class="{ 'router-link-active': open || openingUserRoutes }"
      >
        <app-icon
          class="icon-active"
          icon="i-gts-user-helmet-safety"
        />
        <app-icon
          class="icon-inactive"
          icon="i-gts-user-helmet-safety-gray"
        />
        <div>User</div>
      </menu-button>
    </template>

    <transition name="fade">
      <menu-items
        class="menu-items right-0"
        :class="{
          'top-6': status === 'the-navbar-upper',
          'bottom-16 right-8 sm:right-4': status === 'the-menu-bar',
        }"
      >
        <menu-item v-slot="{ close }">
          <router-link
            v-slot="{ href, navigate }"
            :to="{ name: 'profile settings' }"
          >
            <a
              class="mb-4 block px-4"
              :href="href"
              @click="[navigate(), close()]"
            >
              <div class="flex items-center gap-x-4 border-b pb-3">
                <div class="flex-1 truncate text-right">
                  <div>Hi,</div>
                  <div class="truncate">{{ user.name }}</div>
                </div>
                <app-user-photo
                  class="flex-shrink-0"
                  :src="user.userPhoto"
                  :name="user.name ?? user.email"
                />
              </div>
            </a>
          </router-link>
        </menu-item>

        <menu-item
          v-if="user.hasRole"
          v-slot="{ close }"
        >
          <router-link
            v-slot="{ href, navigate }"
            :to="{ name: 'shopping list history' }"
          >
            <a
              :href="href"
              class="menu-item"
              @click="[navigate(), close()]"
            >
              <span class="inline-block text-right">Daftar Belanja</span>
              <fa-icon icon="i-far-cart-shopping" />
            </a>
          </router-link>
        </menu-item>

        <!-- TODO project menu -->
        <!-- <menu-item v-slot="{ close }">
          <router-link
            v-slot="{ href, navigate }"
            :to="{ name: 'project list' }"
          >
            <a
              :href="href"
              class="menu-item"
              @click="[navigate(), close()]"
            >
              <span class="inline-block text-right">Tracking Proyek</span>
              <fa-icon icon="i-far-wrench" />
            </a>
          </router-link>
        </menu-item> -->

        <menu-item v-slot="{ close }">
          <router-link
            v-slot="{ href, navigate }"
            :to="{ name: 'notification' }"
          >
            <a
              :href="href"
              class="menu-item"
              @click="[navigate(), close()]"
            >
              <span
                v-if="page.unreadNotificationsCount"
                class="h-5 flex-center rounded-full bg-danger-600 px-2 text-xs"
              >
                {{ Math.min(page.unreadNotificationsCount, 99) }}
              </span>
              <span class="inline-block text-right">Notifikasi</span>
              <fa-icon
                icon="i-fal-bell"
                :class="{ 'faa-ring animated': page.unreadNotificationsCount > 0 }"
              />
            </a>
          </router-link>
        </menu-item>

        <menu-item v-slot="{ close }">
          <router-link
            v-slot="{ href, navigate }"
            :to="{ name: 'settings' }"
          >
            <a
              :href="href"
              class="menu-item"
              @click="[navigate(), close()]"
            >
              <span class="inline-block text-right">Pengaturan</span>
              <fa-icon icon="i-far-gear" />
            </a>
          </router-link>
        </menu-item>

        <menu-item v-slot="{ close }">
          <app-button
            class="menu-item"
            @click="[logout(), close()]"
          >
            <span class="inline-block text-right">Keluar</span>
            <fa-icon icon="i-far-power-off" />
          </app-button>
        </menu-item>
      </menu-items>
    </transition>
  </app-menu>
</template>

<style lang="postcss" scoped>
  .menu-items {
    @apply absolute z-20 overflow-hidden w-48 py-4;
    @apply bg-primary-400 text-white max-xl:text-sm;
    @apply shadow-figma ring-1 ring-black ring-opacity-5;
    @apply font-poppins font-normal;
  }

  .menu-item {
    @apply w-full flex cursor-pointer items-center whitespace-nowrap gap-x-4 px-4 py-2 justify-end;

    &:hover,
    &.active {
      @apply bg-primary text-white;
    }

    &.disabled {
      @apply !cursor-not-allowed !text-gray-400 hover:!bg-gray-100;
    }
  }
</style>
