import { onBeforeUnmount } from 'vue'
import { type Ref, watch } from 'vue'

export function useAndroidSwipeRefreshLayoutHandler() {
  const enable = () => {
    window.AndroidSwipeRefreshLayoutHandler?.active()
  }
  const disable = () => {
    window.AndroidSwipeRefreshLayoutHandler?.inactive()
  }

  const setWatcher = (status: Ref<boolean>) => {
    watch(status, () => {
      if (status.value) {
        disable()
        return
      }
      enable()
    })
  }

  onBeforeUnmount(() => {
    window.AndroidSwipeRefreshLayoutHandler?.active()
  })

  return {
    enable,
    disable,
    setWatcher,
  }
}
