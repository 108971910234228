<script setup lang="ts">
  import { useRouter } from 'vue-router'
  import { type RouteLocationRaw } from 'vue-router'

  const props = defineProps<{
    to?: RouteLocationRaw
  }>()

  const router = useRouter()

  const back = () => {
    if (window.history.state.position > 1) {
      router.go(-1)
    } else {
      router.replace(props.to ?? { name: 'home' })
    }
  }
</script>

<template>
  <app-button
    class="cursor-pointer"
    @click="back"
  >
    <slot></slot>
  </app-button>
</template>
