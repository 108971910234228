<script setup lang="ts">
  import { useElementVisibility } from '@vueuse/core'
  import { ref, watchEffect } from 'vue'
  import { debounce } from '@/functions'

  const emit = defineEmits<{
    (e: 'endReached'): void
  }>()

  const sentinel = ref<HTMLElement>()
  const targetIsVisible = useElementVisibility(sentinel)
  const duration = 200
  const debounceOption = { immediate: true }
  const endReached = debounce(
    () => {
      emit('endReached')
    },
    duration,
    debounceOption,
  )
  watchEffect(() => {
    if (targetIsVisible.value) {
      endReached()
    }
  })
</script>

<template>
  <div>
    <slot></slot>
    <div ref="sentinel"></div>
  </div>
</template>
