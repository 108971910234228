import { defineStore } from 'pinia'
import { kebabCase } from '@/functions'

interface State {
  categories: ServiceCategory[]
  loadingCategories: boolean
  loadingItems: boolean
}

export const useServiceStore = defineStore('Service', {
  state: (): State => ({
    categories: [],
    loadingCategories: false,
    loadingItems: false,
  }),
  getters: {
    findCategoryByName: (state: State) => (name: string) => {
      // find category by its kebab case name recursively from categories
      const find = (
        categories: ServiceCategory[],
        name: string,
      ): ServiceCategory | undefined => {
        // find category by its kebab case name recursively from categories
        for (const category of categories) {
          if (kebabCase(category.name) === name) { // top level category
            return category
          }
          if (category.children) {
            const found = find(category.children, name)
            if (found) {
              return found
            }
          }
        }
        return undefined
      }

      return find(state.categories, kebabCase(name))
    },
  },
  actions: {
    setLoadingCategories() {
      this.loadingCategories = true
    },
    setLoadingCategoriesDone() {
      this.loadingCategories = false
    },
    setLoadingItems() {
      this.loadingItems = true
    },
    setLoadingItemsDone() {
      this.loadingItems = false
    },
  },
})
