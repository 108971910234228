import * as Sentry from '@sentry/vue'
import { type App } from 'vue'
import { type Router } from 'vue-router'

export default {
  install(app: App<Element>, router: Router) {
    // dont run in development
    if (import.meta.env.DEV) {
      return
    }

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.01, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/api.solusibangunan.id/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

      // Christ's addition
      release: 'masa-app@' + __APP_VERSION__,
      ignoreErrors: [
        // stackoverflow says this can be safely ignored: https://stackoverflow.com/a/50387233/3671954
        'ResizeObserver loop limit exceeded',
        '/privateSpecialRepair/', // weird vivo browser error
        // axios server common https statuses
        '/Request failed with status code (401|403|404|419|422|429|500|503)/',
        '/Request failed with status code [401|403|404|419|422|429|500|503]/',
        // don't know what caused these errors and why are these reported to sentry
        'Failed to fetch dynamically imported module',
        'Unable to preload CSS',
        'Request aborted',
        'Network Error',
      ],
    })
  },
}
