import { type App } from 'vue'
import AppMarkdownEditor from './app-markdown-editor.vue'
import AppMarkdownPreview from './app-markdown-preview.vue'

export default {
  install(Vue: App) {
    Vue.component('AppMarkdownEditor', AppMarkdownEditor)
    Vue.component('AppMarkdownPreview', AppMarkdownPreview)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppMarkdownEditor: typeof AppMarkdownEditor
    AppMarkdownPreview: typeof AppMarkdownPreview
  }
}
