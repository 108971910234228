<script setup lang="ts">
  import { ref } from 'vue'

  defineProps<{
    multiple?: boolean
    accept?: string
    capture?: 'user' | 'environment'
  }>()

  const emit = defineEmits<{
    (e: 'change', files: File[]): void
  }>()

  const onChange = (event: Event) => {
    const files = (event.target as HTMLInputElement).files
    if (files) {
      emit('change', Array.from(files))
    }
    if (refInput.value) {
      refInput.value.value = ''
    }
  }

  const refInput = ref<HTMLInputElement>()
  const open = () => {
    refInput.value?.click()
  }
  defineExpose({ open })
</script>

<template>
  <div>
    <input
      ref="refInput"
      type="file"
      :multiple="multiple"
      :accept="accept"
      class="hidden"
      :capture="capture"
      @change="onChange"
    />
    <slot :open="open"></slot>
  </div>
</template>
