<script setup lang="ts">
  import { Money3Component as InputMoney } from 'v-money3'
  import { computed, ref, watch } from 'vue'
  import { randomId } from '@/functions'

  defineOptions({
    inheritAttrs: false,
  })

  const props = defineProps<{
    id?: string
    label?: string
    modelValue: string | number
    sublabel?: string
    placeholder?: string
    type?:
      | 'text'
      | 'textarea'
      | 'tel'
      | 'email'
      | 'password'
      | 'date'
      | 'search'
      | 'number'
    required?: boolean
    error?: string | string[]
    autocomplete?: string
    maxlength?: string | number
    disabled?: boolean
    options?: Record<string, unknown>
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', val: string | number): void
  }>()

  const value = computed({
    get: () => props.modelValue,
    set: (val) => {
      const value = props.type === 'number' ? Number(val) : val
      emit('update:modelValue', value)
    },
  })

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)

  const vMoneyOptions = computed(() => ({
    decimal: ',',
    thousands: '.',
    precision: 0,
    ...props.options,
  }))

  const inputIsFocused = ref(false)
  const onFocus = () => {
    inputIsFocused.value = true
  }
  const onBlur = () => {
    inputIsFocused.value = false
  }

  // hide error message when value is changed after the error message is shown
  const oldValue = ref<string | number>('')
  const isDirty = computed(() => value.value !== oldValue.value)
  watch(
    () => props.error,
    () => {
      oldValue.value = value.value
    },
    { immediate: true },
  )
</script>

<template>
  <div :class="{ 'has-error': !!error && !isDirty }">
    <div class="space-y-1">
      <label
        v-if="label"
        :for="inputId"
        class="form-label"
      >
        {{ label }}
        <template v-if="required">
          <span class="form-required inline-block text-danger-600">*</span>
        </template>
      </label>
      <span
        v-if="sublabel"
        class="form-sublabel"
      >
        ({{ sublabel }})
      </span>
      <div class="relative">
        <template v-if="type === 'textarea'">
          <textarea
            :id="inputId"
            v-model="value"
            v-bind="$attrs"
            rows="3"
            class="form-control"
            :maxlength="maxlength"
            :required="required ?? false"
            :placeholder="placeholder"
            :disabled="disabled"
            @focus="onFocus"
            @blur="onBlur"
          ></textarea>
        </template>
        <template v-else-if="type === 'number'">
          <input-money
            :id="inputId"
            v-model="value"
            v-bind="vMoneyOptions"
            :placeholder="placeholder"
            :disabled="disabled"
            class="form-control"
            @focus="onFocus"
            @blur="onBlur"
          />
        </template>
        <template v-else>
          <input
            :id="inputId"
            v-model="value"
            v-bind="$attrs"
            class="form-control"
            :type="type ?? 'text'"
            :maxlength="maxlength"
            :required="required ?? false"
            :disabled="disabled"
            :autocomplete="autocomplete"
            :placeholder="placeholder"
            @focus="onFocus"
            @blur="onBlur"
          />
        </template>
        <slot></slot>
      </div>
    </div>
    <div class="text-right">
      <app-error-msg :msg="error" />
    </div>
  </div>
</template>

<style lang="postcss">
  .form-label {
    @apply text-sm font-bold;
    /* @apply text-primary-400; */
    @apply select-none;
  }
  .form-control {
    @apply block w-full;
    @apply rounded-lg h-9; /* same as .btn */
    /* @apply bg-gray-100 placeholder:text-gray-400 text-black; */
    /* @apply border border-primary placeholder:text-gray-300; */
    @apply border border-gray-200 placeholder:text-gray-300;
    @apply px-4; /* spacing text around input border */
    @apply font-roboto;
  }
  .form-sublabel {
    @apply text-xs text-gray;
  }
  .has-error .form-control {
    @apply border-danger;
  }
  .has-error .form-label {
    @apply text-danger;
  }
  textarea.form-control {
    @apply leading-inherit h-inherit py-2;
  }
</style>
<style lang="postcss" scoped>
  .text-center .form-control,
  .text-center .form-label {
    text-align: center;
  }
</style>
