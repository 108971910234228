import { type Ref, computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { decodeHtml } from '@/functions'
import { useApi } from '@/functions/api'
import { useUserStore } from '@/stores/user'
import { usePageDetail } from './app'

export function useProductList() {
  const route = useRoute()
  const api = useApi()

  const loading = ref(false)
  const products = ref<Product[]>([])
  const pagination = ref<CursorPagination>()

  const getData = async () => {
    if (pagination.value && !pagination.value.next_cursor) return

    if (loading.value) {
      api.abort()
    }

    loading.value = true
    const apiParams = {
      listing: route.params.categoryType === 'jual' ? 'sell' : 'rent',
      category: route.query.kategori,
      brand: route.query.brand,
      cursor: pagination.value?.next_cursor ?? undefined,
      per_page: 48,
    }

    const response = await api.GET<ApiResponseCursor<Product>>('guest/product', apiParams)

    pagination.value = response.meta
    const data = removeSellRentVariantsFromProducts(
      response.data,
      route.params.categoryType as 'jual' | 'sewa',
    )

    products.value.push(...data)
    loading.value = false
  }

  return {
    loading,
    products,
    pagination,
    getData,
  }
}

export function useProductDetail() {
  const route = useRoute()
  const api = useApi()
  const { loading, showNotFoundError, tryCatchNotFound } = usePageDetail()
  const product = ref<ProductDetail>()
  const relatedProducts = ref<Product[]>([])

  const getData = async (id: number) => {
    return tryCatchNotFound(async () => {
      const response = await api.GET<ProductDetail>('guest/product/' + id, {
        listing: route.params.categoryType === 'jual' ? 'sell' : 'rent',
      })

      const variants = removeSellRentVariants(
        response.variants,
        route.params.categoryType as 'jual' | 'sewa',
      )

      const { price, sale_price, regular_price } = variants.length
        ? variants[0]
        : response

      const variant_attributes = response.variant_attributes.filter(
        (attr) => attr.key !== 'pa_jual_sewa',
      )

      product.value = {
        ...response,
        price,
        sale_price,
        regular_price,
        variants: variants.filter((v) => v.attributes.length > 0),
        variant_attributes,
        images: [
          response.image_url,
          ...response.images,
          ...variants.map((variant) => variant.image_url),
        ],
      }

      relatedProducts.value = removeSellRentVariantsFromProducts(
        response.related_products,
        route.params.categoryType as 'jual' | 'sewa',
      )
    })
  }
  return {
    loading,
    product,
    relatedProducts,
    getData,
    showNotFoundError,
  }
}

export function useProductVariantPrices(product: Readonly<Ref<Product | ProductDetail>>) {
  const minPrice = computed(() => {
    if (product.value.variants.length === 0) return product.value.regular_price ?? 0

    const prices = product.value.variants.map((variant) => variant.price ?? 0)
    return Math.min(...prices)
  })
  const maxPrice = computed(() => {
    if (product.value.variants.length === 0) return product.value.regular_price ?? 0

    const prices = product.value.variants.map((variant) => variant.price ?? 0)
    return Math.max(...prices)
  })

  return {
    minPrice,
    maxPrice,
  }
}

function removeSellRentVariants(variants: ProductVariant[], type: 'jual' | 'sewa') {
  // get all variants that does not have pa_jual_sewa attribute
  // or have pa_jual_sewa attribute with value not equal to type
  return variants
    .filter((variant) => {
      // return true if variant does not have pa_jual_sewa attribute
      if (!variant.attributes.some((attr) => attr.key === 'pa_jual_sewa')) {
        return true
      }

      // return true if variant have pa_jual_sewa attribute with value not equal to type
      return variant.attributes.some((attr) => {
        return attr.key === 'pa_jual_sewa' && attr.value.toLowerCase() === type
      })
    })
    .map((variant) => ({
      ...variant,
      attributes: variant.attributes
        .filter((attr) => attr.key !== 'pa_jual_sewa')
        .map((attr) => ({
          key: attr.key,
          value: decodeHtml(attr.value),
          name: decodeHtml(attr.name),
        })),
    }))
}

export function removeSellRentVariantsFromProducts(
  products: Product[],
  type: 'jual' | 'sewa',
) {
  return products.map((product) => {
    const variants = removeSellRentVariants(product.variants, type)
    const variant_attributes = product.variant_attributes.filter(
      (attr) => attr.key !== 'pa_jual_sewa',
    )
    const { price, sale_price, regular_price } = variants.length ? variants[0] : product
    return {
      ...product,
      price,
      sale_price,
      regular_price,
      variants: variants.filter((v) => v.attributes.length > 0),
      variant_attributes,
    }
  })
}

export function findProductVariant(
  product: Product | ProductDetail,
  keyValues: { key: string; value: string }[],
) {
  return product.variants.find((v) => {
    return keyValues.every((kv) => {
      return v.attributes.some((attr) => {
        return attr.key === kv.key && attr.value === kv.value
      })
    })
  })
}

// if user hasRole (admin) or product has price, return product as HasPrice
type ProductAsserted = Product | ProductDetail | ProductVariant | undefined
export function useAssertProductHasPrice<T extends ProductAsserted>(product: Ref<T>) {
  const user = useUserStore()
  const productHasPrice = computed(() => {
    if (user.hasRole || (product.value && typeof product.value.price === 'number')) {
      return product.value as HasPrice<T>
    }
    return undefined
  })
  return {
    productHasPrice,
  }
}
