<script setup lang="ts">
  import { type RouteLocationRaw } from 'vue-router'

  defineProps<{
    to?: RouteLocationRaw
  }>()

  const emit = defineEmits<{
    (e: 'click'): void
  }>()
</script>

<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to ?? ''"
    class="mb-4 flex items-center text-left text-xs text-blue space-x-1"
    @click="emit('click')"
  >
    <fa-icon icon="i-far-chevron-left" />
    <span><slot></slot></span>
  </component>
</template>
