import { vOnClickOutside } from '@vueuse/components'
import { type App } from 'vue'
import focus from './focus'
import textareaAutogrow from './textarea-autogrow'

export default {
  install(app: App) {
    app.directive('focus', focus)
    app.directive('autogrow', textareaAutogrow)
    app.directive('on-click-outside', vOnClickOutside)
  },
}
