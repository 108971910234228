<script setup lang="ts">
  import { helpCenterUrl } from '@/models/app'
  import { useLogout } from '@/models/app'
  import { usePageStore } from '@/stores/page'
  import TheNavbarUserDropdown from './the-navbar-user-dropdown.vue'

  const page = usePageStore()
  const logout = useLogout()
  // using z-30 because product list filter by brand has z-20 to appear on top of navbar lower
</script>

<template>
  <nav
    class="fixed inset-x-0 top-0 z-30 mb-4 bg-primary-100 py-1.5 text-xs text-primary-400 max-lg:hidden"
  >
    <div class="container">
      <div class="flex">
        <app-button
          href="https://play.google.com/store/apps/details?id=id.solusibangunan.app"
          class="mr-4"
        >
          <fa-icon icon="i-far-mobile" />
          Download Aplikasi MASA
        </app-button>
        <app-button
          class="mr-auto"
          :href="helpCenterUrl"
        >
          Help Center
        </app-button>
        <app-button
          :to="{ name: 'home', hash: '#layanan-jual' }"
          class="mr-4"
        >
          Beli Bahan Bangunan
        </app-button>
        <app-button
          :to="{ name: 'home', hash: '#layanan-jasa' }"
          class="mr-4"
        >
          Jasa Renovasi
        </app-button>
        <app-button
          :to="{ name: 'home', hash: '#layanan-sewa' }"
          class="mr-6"
        >
          Sewa Perancah Bangunan
        </app-button>
        <div
          v-if="page.isNotAuthenticated"
          class="font-bold"
        >
          <app-button
            class="font-bold"
            :to="{ name: 'login' }"
          >
            Masuk
          </app-button>
        </div>
        <div
          v-else-if="page.isAuthenticated"
          class="font-bold"
        >
          <the-navbar-user-dropdown status="the-navbar-upper" />
          |
          <app-button
            class="font-bold"
            @click="logout()"
          >
            Keluar
          </app-button>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="postcss" scoped>
  a,
  button {
    @apply hover:underline;
  }
</style>
