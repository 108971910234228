<script setup lang="ts">
  const model = defineModel<string>({ default: '' })
</script>

<template>
  <app-input
    id="page-search"
    v-model="model"
    type="search"
    placeholder="Cari Produk"
  >
    <div class="absolute inset-y-0 left-1 z-20 w-8 flex-center text-xs text-gray">
      <fa-icon icon="i-far-magnifying-glass" />
    </div>

    <app-button
      v-if="model"
      class="absolute inset-y-0 right-1 z-20 my-auto size-6 flex-center rounded-full text-xs text-gray hover:bg-gray-100"
      @click="model = ''"
    >
      <fa-icon icon="i-far-xmark" />
    </app-button>
  </app-input>
</template>

<style lang="postcss" scoped>
  :deep() .form-control {
    @apply pl-10;
  }
</style>
