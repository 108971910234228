import { defineStore } from 'pinia'

export const useProductCategoryStore = defineStore('ProductCategory', {
  state: () => ({
    data: [] as ProductCategory[],
    loading: true,
  }),
  getters: {
    // find category recursively
    findById:
      (state) =>
      (id: number): ProductCategory | null => {
        const find = (category: ProductCategory): ProductCategory | null => {
          if (category.id === id) return category
          if (!category.children) return null
          for (const child of category.children) {
            const found = find(child)
            if (found) return found
          }
          return null
        }
        for (const category of state.data) {
          const found = find(category)
          if (found) return found
        }
        return null
      },
  },
  actions: {
    setData(data: ProductCategory[]) {
      this.data = data
    },
    setLoadingDone() {
      this.loading = false
    },
  },
})
