<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { debounce } from '@/functions'

  const route = useRoute()
  const router = useRouter()
  const searchtext = ref<string>(route.query.cari?.toString() ?? '')

  const submitSearch = () => {
    // if current route is one of these, just append ?cari to the route
    router.push({
      query: {
        ...route.query,
        cari: searchtext.value ? searchtext.value : undefined,
        hlm: undefined,
      },
    })
    return
  }

  const deb = debounce(submitSearch, 500)
  watch(searchtext, (value) => {
    if (value) {
      deb()
    } else {
      router.push({
        query: {
          ...route.query,
          cari: undefined,
          hlm: undefined,
        },
      })
    }
  })
  watch(
    () => route.fullPath,
    () => {
      searchtext.value = route.query.cari?.toString() ?? ''
    },
  )
</script>

<template>
  <form @submit.prevent="submitSearch">
    <app-search-input v-model="searchtext" />
  </form>
</template>
