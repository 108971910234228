<script setup lang="ts">
  import { passwordStrength } from 'check-password-strength'
  import { computed } from 'vue'

  // https://cheatsheetseries.owasp.org/cheatsheets/Authentication_Cheat_Sheet.html
  const props = defineProps<{
    password: string
  }>()

  /**
   * 0 = too weak
   * 1 = weak
   * 2 = medium
   * 3 = strong
   */
  const strength = computed(() => passwordStrength(props.password).id)
</script>

<template>
  <div class="flex items-center gap-x-2">
    <div class="flex flex-1 gap-x-1">
      <div
        :class="password.length >= 8 ? 'bg-success-500' : 'bg-gray'"
        class="h-0.5 flex-1 opacity-70"
      ></div>
      <div
        :class="password.length >= 8 && strength > 0 ? 'bg-success-500' : 'bg-gray'"
        class="h-0.5 flex-1 opacity-80"
      ></div>
      <div
        :class="password.length >= 8 && strength > 1 ? 'bg-success-500' : 'bg-gray'"
        class="h-0.5 flex-1 opacity-90"
      ></div>
      <div
        :class="password.length >= 8 && strength > 2 ? 'bg-success-500' : 'bg-gray'"
        class="h-0.5 flex-1 opacity-100"
      ></div>
    </div>
    <div
      class="w-22 text-right text-xs"
      :class="password.length >= 8 ? 'text-success-500' : 'text-gray'"
    >
      <template v-if="password.length < 8">Terlalu Pendek</template>
      <template v-else-if="strength === 0">Sangat Rawan</template>
      <template v-else-if="strength === 1">Rawan</template>
      <template v-else-if="strength === 2">Kuat</template>
      <template v-else-if="strength === 3">Sangat Kuat</template>
    </div>
  </div>
</template>
