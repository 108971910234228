import { createRouter, createWebHistory } from 'vue-router'
import { usePageStore } from '@/stores/page'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    // don't scroll to saved position when:
    // - user clicks menu bar layanan
    // - user navigate back to home with breadcrumb
    // - user refresh page home with hash
    if (!from.name) {
      return
    }
    if (to.name === 'home' && to.hash && from.name === 'home') {
      return
    }

    return new Promise((resolve) => {
      // wait for page transition
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition)
        } else {
          resolve({ left: 0, top: 0 })
        }
      }, 250)
    })
  },
})

router.beforeEach((to, from, next) => {
  const page = usePageStore()

  if (!to.name) {
    throw new Error('Page must have a name.')
  }
  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  page.setTitle(to.meta.title)
  page.setSubtitle('')

  next()
})

export default router

/**
 * Make sure all routes has meta with these attributes.
 */
declare module 'vue-router' {
  interface RouteMeta {
    title: string
  }
}
