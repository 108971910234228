<script setup lang="ts">
  import DOMPurify from 'dompurify'
  import EasyMDE from 'easymde'
  import 'easymde/dist/easymde.min.css'
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { debounce } from '@/functions'
  import './markdown.css'

  const props = defineProps<{
    modelValue: string
    placeholder?: string
  }>()
  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
  }>()

  const textArea = ref<HTMLTextAreaElement>()

  const options: EasyMDE.Options = {
    status: false,
    sideBySideFullscreen: false,
    spellChecker: false,
    forceSync: true,
    minHeight: '200px',
    // toolbar: [
    //   'bold',
    //   'italic',
    //   'strikethrough',
    //   '|',
    //   'heading',
    //   'unordered-list',
    //   'ordered-list',
    //   'quote',
    //   '|',
    //   'link',
    //   // 'image',
    //   // 'upload-image',
    //   'table',
    //   'horizontal-rule',
    //   '|',
    //   'undo',
    //   'redo',
    //   // 'clean-block',
    //   '|',
    //   // 'preview',
    //   // 'side-by-side',
    //   // 'fullscreen',
    //   'guide',
    // ],
    toolbar: [],
    renderingConfig: {
      sanitizerFunction: DOMPurify.sanitize,
    },
    placeholder: props.placeholder ?? 'Ketik disini...',
  }

  const isTyping = ref(false) // prevent watch from triggering when typing
  const finishTyping = debounce(() => {
    isTyping.value = false
  }, 1000)
  let easyMDE: EasyMDE | undefined
  onMounted(() => {
    easyMDE = new EasyMDE({ ...options, element: textArea.value })
    easyMDE.value(props.modelValue) // set initial value
    easyMDE.codemirror.on('change', () => {
      emit('update:modelValue', easyMDE?.value() ?? '')
      isTyping.value = true
      finishTyping()
    })
    easyMDE?.codemirror.getWrapperElement().parentElement?.classList.add('scroll-mt-12')
    easyMDE.codemirror.on('focus', () => {
      easyMDE?.codemirror.getWrapperElement().parentElement?.scrollIntoView({
        behavior: 'smooth',
      })
    })
  })
  onBeforeUnmount(() => {
    easyMDE?.cleanup()
    easyMDE?.toTextArea()
    easyMDE = undefined
  })
  watch(
    () => props.modelValue,
    (newValue) => {
      // prevent watch from triggering when typing
      // setting value when typing will move the cursor to the start of the text
      if (!isTyping.value) {
        easyMDE?.value(newValue ?? '')
      }
    },
  )

  const setFocus = () => {
    easyMDE?.codemirror.focus()
  }
  defineExpose({
    setFocus,
  })
</script>

<template>
  <div @keydown.ctrl.enter="isTyping = false">
    <textarea ref="textArea"></textarea>
  </div>
</template>
