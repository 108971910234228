<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt } from '@/functions'
  import { randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    max?: number | string
    min?: number | string
    disabled?: boolean
    label?: string
    required?: boolean
    placeholder?: string
    autocomplete?: string
    error?: string | string[]
    modelValue: string
  }>()

  const emit = defineEmits<{
    (e: 'focus', event: Event): void
    (e: 'blur', event: Event): void
    (e: 'input', event: Event): void
    (e: 'update:modelValue', value: string): void
  }>()

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)

  const formattedDate = computed(() => {
    if (props.modelValue) {
      return fmt.date(props.modelValue, 'd MMM yyyy')
    }

    return ''
  })
  const value = computed({
    set: (text: string) => {
      emit('update:modelValue', text)
    },
    get: () => props.modelValue,
  })
  const openDatePicker = () => {
    const el = document.getElementById('hidden-date-' + inputId.value)
    if (el && el instanceof HTMLInputElement) {
      el.showPicker()
    }
    document.getElementById(inputId.value)?.focus()
  }
  const onFocus = (event: Event) => {
    if (event.target && event.target instanceof HTMLInputElement) {
      event.target.click()
    }
  }
</script>

<template>
  <app-input
    :id="inputId"
    v-model="formattedDate"
    :autocomplete="autocomplete"
    readonly
    :label="label"
    :placeholder="placeholder"
    :required="required ?? false"
    :disabled="disabled"
    :error="error"
    @blur="emit('blur', $event)"
    @focus="onFocus"
    @click="openDatePicker()"
  >
    <div class="pointer-events-none absolute inset-y-0 right-3 flex items-center text-sm">
      <fa-icon icon="i-far-calendar" />
    </div>
    <input
      :id="'hidden-date-' + inputId"
      v-model="value"
      type="date"
      :max="max"
      :min="min"
      class="absolute inset-0 opacity-0 -z-1"
      tabindex="9999"
      @change="emit('input', $event)"
    />
  </app-input>
</template>
