import { defineStore } from 'pinia'
import { randomId } from '@/functions'

interface State {
  recipient: string
  notes: string
  items: ShoppingListItem[]
  url: string
  modal?: AppModalSlideRight
  charges: FormShoppingListCharge[]
}

const emptyCharge = {
  label: '',
  type: 'diskon',
  value: 0,
}

const depositMultiplier = 3
const LOCAL_STORAGE_KEY = 'shopping-list-v2'

// this store is subcribed and stored to localStorage on layout-app.vue
export const useShoppingListStore = defineStore('ShoppingList', {
  state: (): State => ({
    recipient: '',
    notes: '',
    items: [],
    url: '',
    charges: [],
  }),
  getters: {
    depositMultiplier: () => depositMultiplier,
    total(state) {
      return () => {
        const totalProduct = state.items.reduce(
          (carry, item) => carry + this.itemSubtotal(item),
          0,
        )
        return state.charges.reduce((carry, { type, value }) => {
          const computedValue = value > 100 ? value : Math.round((carry * value) / 100)
          if (type === 'diskon') {
            return carry - computedValue
          } else {
            return carry + computedValue
          }
        }, totalProduct)
      }
    },
    countProduct: (state) => {
      return state.items.reduce((carry, product) => carry + Number(product.qty), 0)
    },
    unitPriceAfterCharge: () => (item: ShoppingListItem) => {
      let chargeValue = item.charge_nominal
      if (item.charge_percentage) {
        chargeValue = Math.round((item.price * item.charge_percentage) / 100)
      }
      if (item.charge_type === 'diskon') {
        chargeValue *= -1
      }
      return item.price + chargeValue
    },
    itemDepositValue() {
      return (item: ShoppingListItem) => {
        if (item.product_type !== 'sewa' || !item.rent_attributes) {
          return 0
        }
        return (
          this.unitPriceAfterCharge(item) *
          item.qty *
          item.rent_attributes.duration *
          this.depositMultiplier
        )
      }
    },
    itemSubtotal() {
      return (item: ShoppingListItem) => {
        const priceAfterQty = this.unitPriceAfterCharge(item) * item.qty
        if (item.product_type !== 'sewa' || !item.rent_attributes) {
          return priceAfterQty
        }
        return (
          priceAfterQty * item.rent_attributes.duration + item.rent_attributes.deposit
        )
      }
    },
  },
  actions: {
    load() {
      const data = localStorage.getItem(LOCAL_STORAGE_KEY)
      if (data) {
        this.$patch(JSON.parse(data))
      }
    },
    saveToLocalStorage(state: State) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
    },
    setLink(newUrl: string) {
      this.$patch((state) => {
        state.url = newUrl
      })
    },
    reset() {
      this.items = []
      this.charges = []
      this.url = ''
    },
    add(data: ShoppingListItem) {
      data.price = data.price ?? 0
      this.items.push(data)
    },
    findIndexItem(item: ShoppingListItem) {
      return this.items.findIndex(
        (d) =>
          d.product_id === item.product_id &&
          d.product_variant_id === item.product_variant_id &&
          d.product_type === item.product_type,
      )
    },
    editQty(item: ShoppingListItem, qty: number) {
      const index = this.findIndexItem(item)
      this.items[index].qty = qty
    },
    editRentDuration(item: ShoppingListItem, duration: number) {
      const index = this.findIndexItem(item)
      this.items[index].rent_attributes = {
        duration,
        deposit: this.items[index].rent_attributes?.deposit ?? 0,
      }
    },
    editRentDeposit(item: ShoppingListItem, deposit: number) {
      const index = this.findIndexItem(item)
      this.items[index].rent_attributes = {
        deposit,
        duration: this.items[index].rent_attributes?.duration ?? 1,
      }
    },
    remove(item: ShoppingListItem) {
      const index = this.findIndexItem(item)
      this.items.splice(index, 1)
    },
    setRecipient(recipient: string = '') {
      this.recipient = recipient
    },
    setNotes(notes: string = '') {
      this.notes = notes
    },
    addChargeDiscount() {
      this.charges.push({ ...emptyCharge, type: 'diskon', id: randomId() })
    },
    addChargeFee() {
      this.charges.push({ ...emptyCharge, type: 'biaya', id: randomId() })
    },
    removeCharges(id: number | string) {
      this.charges = this.charges.filter((c) => c.id !== id)
    },
  },
})
