const getInputEl = (el: HTMLElement) => {
  return el.querySelector<HTMLInputElement>('textarea,input')
}
const updateHeight = (element: HTMLInputElement) => {
  element.style.height = ''
  element.style.height = element.scrollHeight + 10 + 'px'
}
const textareaInput = (event: Event) => {
  if (!event.currentTarget) return
  updateHeight(event.currentTarget as HTMLInputElement)
}

const mounted = function (el: HTMLElement) {
  const input = getInputEl(el)
  if (input) {
    updateHeight(input)
    input.addEventListener('input', textareaInput)
  }
}
const unmounted = function (el: HTMLElement) {
  const input = getInputEl(el)
  if (input) {
    input.removeEventListener('input', textareaInput)
  }
}
export default {
  mounted,
  unmounted,
}
