<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt } from '@/functions'

  const props = defineProps<{
    regularPrice: number
    salePrice: number
  }>()

  const discountPercentage = computed(() => {
    const discount = props.regularPrice - props.salePrice
    const percentage = Math.round((discount / props.regularPrice) * 100)

    if (percentage === 100 && props.regularPrice !== props.salePrice) return 99 // return 99% if percentage is 100%
    if (percentage === 0 && props.regularPrice > 0 && props.salePrice > 0) return 1 // return 1% if percentage is rounded to 0

    return percentage
  })

  const show = computed(() => props.salePrice < props.regularPrice)
</script>

<template>
  <div
    v-if="show"
    class="flex items-center space-x-2"
  >
    <div class="rounded bg-success-200 px-1 py-0.5 text-3xs text-success-600 font-semibold">
      {{ discountPercentage }}%
    </div>
    <div class="text-2xs text-gray line-through">{{ fmt.number(regularPrice) }}</div>
  </div>
</template>
